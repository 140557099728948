import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from "@material-tailwind/react";
import React from "react";
import {format} from "date-fns";
import {pl} from "react-day-picker/locale";
import {PlannedMessageComponent} from "../PlannedMessage";
import {useStore} from "../../store/store";

export const DayViewModal = (props: {open: boolean, setOpen: Function, data: any}) => {
    const store = useStore();
    const handleOpen = () => props.setOpen(!props.open);
    if(props.data == null){
        return <></>
    }
    const sendMessage = (message: any) => {
        store.sendNow(message.id).then(e=> {
            store.getPlannedMessages().then(e=> {
                window.location.reload();
            })
        })
    }
    const cancelMessage = (message: any) => {
        store.cancelPlannedMessage(message.id).then(e=> {
            store.getPlannedMessages().then(e=> {
                window.location.reload();
            })
        })
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-transparent "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>{format(props.data.date, "PPP", {locale: pl})}</DialogHeader>
            <DialogBody className={'text-white space-y-2'}>
                {props.data.messages.map((e: any)=> <PlannedMessageComponent message={e} channel={props.data.channels.find((d: any) => d.id === e.channel)} sendNow={sendMessage} cancelSend={cancelMessage}/>)}
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
