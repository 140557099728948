import React from "react";
import {observer} from "mobx-react";
import {Outlet, useLocation} from "react-router";
import {useStore} from "../store/store";
import {parseSnackbarColor, parseSnackbarType, parseSnackbarTypeColor} from "./model/Snackbar";
import {useTranslation} from "react-i18next";
import {When} from "react-if";
import Loader from "./Loader";
import {LandingNavbar} from "./navigation/LandingNavbar";
const Footer = React.lazy(async () => ({ default: (await import('./Footer')).Footer }))


export const Layout: React.FC = observer(() => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    return (
        <>
            <div className={`w-screen h-screen overflow-hidden ${location.pathname == '/' ? 'bg-white' : 'bg-black'}`}  contextMenu={"return false;"}>
                <div className={`${location.pathname == '/' ? 'bg-white' : 'bg-black'}`}/>
                <div className={"h-screen w-screen z-20 "}>
                    <div className={"h-1/12"}>
                        <LandingNavbar/>
                    </div>
                    <div className={"h-11/12 w-full overflow-y-scroll overflow-x-hidden scrollbar space-y-5"}>
                        <When condition={store.loading}>
                            <Loader/>
                        </When>
                        <Outlet/>
                        <When condition={!store.loading}>
                            <Footer/>
                        </When>
                    </div>
                    <div className={"fixed bottom-0"}>
                        {store.snackbar.filter(e => !e.hide).map((e: any) => (
                            <div className={"text-center py-2 xl:px-4 items-center justify-center"} key={e.timestamp}>
                                <div
                                    className={`p-2 ${parseSnackbarColor(e.type)} items-center text-white leading-none xl:rounded-full flex xl:inline-flex`}>
                            <span
                                className={`flex rounded-full ${parseSnackbarTypeColor(e.type)} uppercase px-2 py-1 text-xs font-bold mr-3`}>{parseSnackbarType(e.type)}</span>
                                    <span
                                        className={"font-semibold mr-2 text-left flex-auto"}>{e.message[i18n.language]}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
});
