import { useNavigate } from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { Else, If, Then } from "react-if";
import {useSearchParams} from "react-router-dom";
export const LoginPage = () => {
    const navigate = useNavigate();
    const store = useStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const status = searchParams.get("success") === 'true';
    const message = decodeURIComponent(searchParams.get('message') as string);
    // @ts-ignore
    useEffect(() => {
        if(token != undefined){
            const response = fetch(`${store.apiURL}/user/@me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then((e)=> {
                e.json().then((m)=> {
                    const data = m.data;
                    store.setToken(token);
                    store.setUser(data);
                })
            });
        }
    });
    return (
        <>
            <div>
                <section className="overflow-y-auto select-none flex flex-col text-white">
                    <div className={"flex flex-col items-center justify-center h-screen text-xl text-white font-sans"}>
                        <If condition={status}>
                            <Then>
                                <div className={"text-green-500 text-2xl xl:text-4xl font-bold"}>Sukces</div>
                                <div className={"text-lg xl:text-md font-bold"}>Zostałeś pomyślnie zalogowany/a
                                </div>
                                <button
                                    className="flex items-center mt-10 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                                    onClick={() => {
                                        navigate({pathname: '/profile/'+store.user.id})
                                    }}
                                >
                                    Przejdź do profilu
                                </button>
                            </Then>
                            <Else>
                                <Then>
                                    <div className={"text-red-500 text-2xl font-bold"}>Błąd logowania</div>
                                    <div className={"text-lg font-bold"}>Zbieramy informację o twoim problemie</div>
                                    <div className={"text-gray-500 text-sm font-bold"}>Odpowiedź z serwera: {message}</div>
                                    <button
                                        className="flex items-center mt-10 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                                        onClick={() => {
                                            navigate({pathname: '/'})
                                        }}
                                    >
                                        Wróć na stronę główną
                                    </button>
                                </Then>
                            </Else>
                        </If>
                    </div>
                </section>
            </div>
        </>
    );
};
