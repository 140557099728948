import {Tooltip, Typography} from "@material-tailwind/react";
import React from "react";
import {useTranslation} from "react-i18next";

export const Badge = (props: {badge: any}) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Tooltip
                className={'bg-[#111111]'}
                placement={'bottom'}
                content={
                    <div className="flex flex-row items-center">
                        <img className={"h-10"}
                             src={`https://cdn.discordapp.com/emojis/${props.badge.emoji}.webp?size=96`}/>
                        <div>
                            <Typography color="white" className="font-medium">
                                <div
                                    className={"text-xl font-bold font-montserrat"}>{props.badge.name[i18n.language]}
                                </div>
                            </Typography>
                            <Typography
                                variant="small"
                                color="white"
                                className="font-normal opacity-80"
                            >
                                <div
                                    className={"text-gray-500 text-lg font-semibold"}>{t('profile.badges.' + props.badge.type)}
                                </div>
                            </Typography>
                        </div>
                    </div>
                }
            >
                <img className={"h-8 w-8"}
                     src={`https://cdn.discordapp.com/emojis/${props.badge.emoji}.webp?size=96`}/>
            </Tooltip>
        </>
    );
};
