import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input, Option, Select,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import {When} from "react-if";
import {BadgeItem} from "../BadgeItem";
import {useStore} from "../../store/store";
import {JSX} from "react/jsx-runtime";

export const InsertParamModal = (props: {open: boolean, setOpen: Function, setData: Function}) => {
    const store = useStore();
    const handleOpen = () => props.setOpen(!props.open);
    const [param, setParam] = useState(null);
    const [value, setValue] = useState(null);
    const [type, setType] = useState('text');
    const [roles, setRoles] = useState([]);
    const [emoji, setEmoji] = useState([]);
    const [channels, setChannels] = useState([]);
    const save = () => {
        props.setData({
            prop: param,
            value: value
        });
        handleOpen();
    }
    useEffect(()=> {
        store.getChannels().then(e=> {
            setChannels(e);
        });
        store.getRoles().then(e=> {
            setRoles(e);
        });
        store.getEmojis().then(e=> {
            setEmoji(e);
        })
    }, [])
    const buildOptionsForChannel = () => {
        let option: JSX.Element[] = [];
        const d = channels.filter((e: any) => e.type == 4).sort((e: any, d: any) => e.rawPosition - d.rawPosition);
        d.forEach((e: any)=> {
            option.push(<Option value={e.id} disabled={true}>{e.name}</Option>)
            const m = channels.filter((x:any) => x.parentId === e.id);
            m.sort((e :any,d :any) => e.rawPosition - d.rawPosition );
            m.map((e:any) => option.push(<Option key={e.id} value={e.id}>{e.name}</Option>))
        })
        return option;
    }
    const buildOptionsForRoles = () => {
        let option: JSX.Element[] = [];
        const d = roles.sort((e: any, d: any) => d.rawPosition - e.rawPosition);
        d.forEach((e: any)=> {
            option.push(<Option key={e.id} style={{color: e.color == 0 ? 'white' : '#'+(e.color & 0x00FFFFFF).toString(16).padStart(6, '0')}} value={e.id}>{e.name}</Option>)
        })
        return option;
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>Utwórz wartość konfiguracji</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'mb-5'}>Tę wartość wykorzystuje bot do obsługi treści w bocie</div>
                <div className="space-y-5">
                    <Input onInput={(e : any) => setParam(e.target.value)} onPaste={(e : any) => setParam(e.target.value)} labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Nazwa wartości"/>
                    <Select className={'text-white font-montserrat'}
                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                            menuProps={{className: 'bg-black'}}
                            color={'blue'}
                            label={'Wybierz rodzaj wartości'}
                            onChange={(e: any) => setType(e)}
                            >
                        <Option value={'text'} className={'flex items-center gap-2'}>Text</Option>
                        <Option value={'channel'} className={'flex items-center gap-2'}>Kanał</Option>
                        <Option value={'role'} className={'flex items-center gap-2'}>Rola</Option>
                        <Option value={'emoji'} className={'flex items-center gap-2'}>Emotka serwerowa</Option>
                        <Option value={'boolean'} className={'flex items-center gap-2'}>Boolean</Option>
                        <Option value={'integer'} className={'flex items-center gap-2'}>Liczba</Option>
                        <Option value={'date'} className={'flex items-center gap-2'}>Data</Option>
                    </Select>
                    <When condition={type == 'text'}>
                        <Input onInput={(e : any) => setValue(e.target.value)} onPaste={(e : any) => setValue(e.target.value)} labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Wartość"/>
                    </When>
                    <When condition={type == 'integer'}>
                        <Input type={'number'} onInput={(e : any) => setValue(e.target.value)} onPaste={(e : any) => setValue(e.target.value)} labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Wartość"/>
                    </When>
                    <When condition={type == 'date'}>
                        <Input type={'datetime-local'} onInput={(e : any) => setValue(e.target.value)} onPaste={(e : any) => setValue(e.target.value)} labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Wartość"/>
                    </When>
                    <When condition={type == 'boolean'}>
                        <Select className={'text-white font-montserrat'}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                menuProps={{className: 'bg-black'}}
                                color={'blue'}
                                label={'Wybierz rodzaj wartości'}
                                onChange={(e: any) => setValue(e)}
                        >
                            <Option value={'true'} className={'flex items-center gap-2'}>True</Option>
                            <Option value={'false'} className={'flex items-center gap-2'}>False</Option>
                        </Select>
                    </When>
                    <When condition={type == 'channel'}>
                        <Select className={'text-white font-montserrat'}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                menuProps={{className: 'bg-black'}}
                                color={'blue'}
                                label={'Wybierz kanał'}
                                onChange={(e: any) => setValue(e)}>
                            {buildOptionsForChannel()}
                        </Select>
                    </When>
                    <When condition={type == 'role'}>
                        <Select className={'text-white font-montserrat'}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                menuProps={{className: 'bg-black'}}
                                color={'blue'}
                                label={'Wybierz role'}
                                onChange={(e: any) => setValue(e)}>
                            {buildOptionsForRoles()}
                        </Select>
                    </When>
                    <When condition={type == 'emoji'}>
                        <Select className={'text-white font-montserrat'}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                menuProps={{className: 'bg-black'}}
                                color={'blue'}
                                label={'Wybierz emoji'}
                                onChange={(e: any) => setValue(e)}
                                selected={(element) =>
                                    element &&
                                    React.cloneElement(element, {
                                        disabled: true,
                                        className:
                                            "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                                    })
                                }>
                            {emoji.map((e: any)=> <Option key={e.id} value={e.id} className={'flex items-center gap-2'}><img src={e.imageURL} className={'h-5 w-5 mr-2 object-cover'}/>{e.name}</Option>)}
                        </Select>
                    </When>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="gradient" color="green" onClick={save}>
                    <span>Utwórz</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
