import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {If, Then} from "react-if";
import {observer} from "mobx-react";
import {parseMonth} from "../utils/global";
import {DebugType} from "../components/model/Snackbar";
import {Input, Option, Select} from "@material-tailwind/react";

const NoAccess = React.lazy(async () => ({ default: (await import('../components/NoAccess')).NoAccess }))
const SuccessButton = React.lazy(async () => ({ default: (await import('../components/ui/SuccessButton')).SuccessButton }))

export const TicketAnalytic: React.FC = observer(() => {
    const store = useStore();
    const [downloadYear, setDownloadYear] = useState(false);
    const [downloadMonth, setDownloadMonth] = useState(false);
    const [downloadUser, setDownloadUser] = useState(false);
    const [yearDetails, setYearDetails]:any = useState(null);
    const [monthDetails, setMonthDetails]:any = useState(null);
    const [userDetailsMonth, setUserDetailsMonth]:any = useState(null);
    const [userDetailsYear, setUserDetailsYear]:any = useState(null);
    const [id, setId] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const runCheckForMonthRaport = () => {
        if(year == null){
            store.addSnackbar({
                message: {
                    pl: "Rok powinien zostać podany",
                    en: 'Year must be fill'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        if(month == null){
            store.addSnackbar({
                message: {
                    pl: "Miesiąc powinien zostać podany",
                    en: 'Month must be fill'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        setDownloadMonth(true);
        store.downloadRaportMonth(year, month).finally(() => {
            store.loadRaportDetails('MONTH').then(e=> {
                setMonthDetails(e);
            }).finally(()=> setDownloadMonth(false));
        });
    }
    const focusOut = () => {
        store.loadRaportDetails('MONTH', `${id!}`).then(e=> {
            setUserDetailsMonth(e);
            store.loadRaportDetails('YEAR', `${id!}`).then(e=> {
                setUserDetailsYear(e);
            })
        })
    }
    const runCheckForYearRaport = () => {
        if(year == null){
            store.addSnackbar({
                message: {
                    pl: "Rok powinien zostać podany",
                    en: 'Year must be fill'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        setDownloadYear(true)
        store.downloadRaportYear(year).finally(() => {
            store.loadRaportDetails('YEAR').then(e=> {
                setYearDetails(e);
            }).finally(()=> setDownloadYear(false));
        });
    }
    const runCheckForUserRaport = () => {
        if(id == null){
            store.addSnackbar({
                message: {
                    pl: "Do wygenerowania tego raportu identyfikator użytkownika jest wymagany",
                    en: 'To generate moderator raport we needed the user ID'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        if(year == null){
            store.addSnackbar({
                message: {
                    pl: "Rok powinien zostać podany",
                    en: 'Year must be fill'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        setDownloadUser(true)
        store.downloadModeratorRaport(id, year, month).finally(() => {
            setDownloadUser(false);
        });
    }
    useEffect(() => {
        store.setLoading(true);
        console.log('load')
        store.loadRaportDetails('YEAR').then(e=> {
            setYearDetails(e);
        }).finally(()=> {
            store.loadRaportDetails('MONTH').then(e=> {
                setMonthDetails(e);
            }).finally(()=> {
                store.setLoading(false);
            })
        });
    }, []);
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {(store.user.permission & 2) === 2 ? (
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-48"}>
                                    {/*<pre>*/}
                                    {/*    {JSON.stringify(yearDetails, null, 2)}*/}
                                    {/*</pre>*/}
                                    <div className={"text-4xl font-bold"}>Analityka ticketów</div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={"grid grid-cols-1 xl:grid-cols-2 gap-10"}>
                                        <div>
                                            <div className={"text-2xl font-bold"}>Raport roczny</div>
                                            {(store.user && (store.user.permission & 4) === 4) && yearDetails != null ? (
                                                <div className={"text-sm text-gray-300 mb-10"}>Ostatnio generowano
                                                    raport na
                                                    rok <b>{yearDetails!.first_value}</b> przez <b>{yearDetails!.generated_user.username}</b><br/>w
                                                    dniu <b>{new Date(yearDetails!.generated_at).toLocaleDateString()}</b> o
                                                    godzinię <b>{new Date(yearDetails!.generated_at).toLocaleTimeString()}</b>
                                                </div>) : <></>}
                                            <Select className={'text-white'} size={'lg'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    color={'blue'}
                                                    label={'Wybierz rok'}
                                                    disabled={store.user && (store.user.permission & 32) != 32}
                                                    onChange={(e: any) => setYear(e)}>
                                                <Option value="2023">2023</Option>
                                                <Option value="2024">2024</Option>
                                            </Select>
                                            <div className={"mt-5 flex items-end justify-end"}>
                                                <SuccessButton label={"Pobierz raport"} press={() => {
                                                    runCheckForYearRaport()
                                                }} loading={downloadYear}/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-2xl font-bold"}>Raport miesięczny</div>
                                            {(store.user && (store.user.permission & 16) === 16) && monthDetails != null ? (<div className={"text-sm text-gray-300 mb-10"}>Ostatnio generowano
                                                raport na
                                                miesiąc <b>{parseMonth(Number(monthDetails!.second_value))} {monthDetails!.first_value}</b> przez <b>{monthDetails!.generated_user.username}</b><br/>w
                                                dniu <b>{new Date(monthDetails!.generated_at).toLocaleDateString()}</b> o
                                                godzinię <b>{new Date(monthDetails!.generated_at).toLocaleTimeString()}</b>
                                            </div>) : <></>}

                                            <Select className={'text-white'} size={'lg'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    color={'blue'}
                                                    label={'Wybierz rok'}
                                                    disabled={store.user && (store.user.permission & 32) != 32}
                                                    onChange={(e: any) => setYear(e)}>
                                                <Option value="2023">2023</Option>
                                                <Option value="2024">2024</Option>
                                            </Select>
                                            <div className={'my-5'}></div>
                                            <Select className={'text-white'} size={'lg'} offset={1} menuProps={{className: 'h-32'}} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    color={'blue'}
                                                    label={'Wybierz miesiąc (Opcjonalne)'}
                                                    disabled={store.user && (store.user.permission & 32) != 32}
                                                    onChange={(e: any) => setMonth(e)}>
                                                <Option value="1">Styczeń</Option>
                                                <Option value="2">Luty</Option>
                                                <Option value="3">Marzec</Option>
                                                <Option value="4">Kwiecień</Option>
                                                <Option value="5">Maj</Option>
                                                <Option value="6">Czerwiec</Option>
                                                <Option value="7">Lipiec</Option>
                                                <Option value="8">Sierpień</Option>
                                                <Option value="9">Wrzesień</Option>
                                                <Option value="10">Październik</Option>
                                                <Option value="11">Listopad</Option>
                                                <Option value="12">Grudzień</Option>
                                            </Select>
                                            <div className={"mt-5 flex items-end justify-end"}>
                                                <SuccessButton label={"Pobierz raport"} press={() => {
                                                    runCheckForMonthRaport()
                                                }} loading={downloadMonth}/>
                                            </div>
                                        </div>
                                        <div
                                            className={"w-full xl:col-start-1 xl:col-end-3 grid grid-cols-1 xl:grid-cols-2 gap-10"}>
                                            <div>
                                                <div className={"text-2xl font-bold mb-5"}>Raport moderatora</div>
                                                <Input type={'number'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Identyfikator moderatora"} color={'blue'} crossOrigin={''} disabled={store.user && (store.user.permission & 32) != 32}
                                                       onBlur={(e: any) => {
                                                           console.log(e);
                                                           setId(e.target.value);
                                                           focusOut()
                                                       }} onPaste={(e: any) => {
                                                    console.log(e);
                                                    setId(e.target.value);
                                                    focusOut()
                                                }} className="text-white "/>
                                                <If condition={(store.user && (store.user.permission & 64) === 64) && userDetailsYear !=null && userDetailsMonth !=null}>
                                                    <Then>
                                                        {userDetailsYear != null ? (
                                                            <div className={"text-sm text-gray-300 mb-10"}>Ostatnio
                                                                generowano
                                                                raport moderatora na
                                                                rok <b>{userDetailsYear!.first_value}</b> przez <b>{userDetailsYear!.generated_user.username}</b><br/>w
                                                                dniu <b>{new Date(userDetailsYear!.generated_at).toLocaleDateString()}</b> o
                                                                godzinię <b>{new Date(userDetailsYear!.generated_at).toLocaleTimeString()}</b>
                                                            </div>) : <div></div>}
                                                        {userDetailsMonth != null ? (
                                                            <div className={"text-sm text-gray-300 mb-10"}>Ostatnio
                                                                generowano
                                                                raport moderatora na
                                                                miesiąc <b>{parseMonth(Number(userDetailsMonth!.second_value))} {userDetailsMonth!.first_value}</b> przez <b>{userDetailsMonth!.generated_user.username}</b><br/>w
                                                                dniu <b>{new Date(userDetailsMonth!.generated_at).toLocaleDateString()}</b> o
                                                                godzinię <b>{new Date(userDetailsMonth!.generated_at).toLocaleTimeString()}</b>
                                                            </div>) : <div></div>}
                                                    </Then>
                                                </If>
                                            </div>
                                            <div className={"xl:mt-12"}>
                                                <Select className={'text-white'} size={'lg'}
                                                        labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                        color={'blue'}
                                                        label={'Wybierz rok'}
                                                        disabled={store.user && (store.user.permission & 32) != 32}
                                                        onChange={(e: any) => setYear(e)}>
                                                    <Option value="2023">2023</Option>
                                                    <Option value="2024">2024</Option>
                                                </Select>
                                                <div className={'my-5'}></div>
                                                <Select className={'text-white'} size={'lg'} offset={1}
                                                        menuProps={{className: 'h-32'}}
                                                        labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                        color={'blue'}
                                                        label={'Wybierz miesiąc (Opcjonalne)'}
                                                        disabled={store.user && (store.user.permission & 32) != 32}
                                                        onChange={(e: any) => setMonth(e)}>
                                                    <Option value="1">Styczeń</Option>
                                                    <Option value="2">Luty</Option>
                                                    <Option value="3">Marzec</Option>
                                                    <Option value="4">Kwiecień</Option>
                                                    <Option value="5">Maj</Option>
                                                    <Option value="6">Czerwiec</Option>
                                                    <Option value="7">Lipiec</Option>
                                                    <Option value="8">Sierpień</Option>
                                                    <Option value="9">Wrzesień</Option>
                                                    <Option value="10">Październik</Option>
                                                    <Option value="11">Listopad</Option>
                                                    <Option value="12">Grudzień</Option>
                                                </Select>
                                                <div className={"mt-5 flex items-end justify-end"}>
                                                    <SuccessButton label={"Pobierz raport"} press={() => {
                                                        runCheckForUserRaport()
                                                    }} loading={downloadUser}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className={'bg-gray-900 rounded-2xl p-2 w-full'}>*/}
                                    {/*    {store.snackbar.map(e => (<>*/}
                                    {/*        <div>*/}
                                    {/*            <div className={parseSnackbarTypeColor(e.type)}>{e.type}</div>*/}
                                    {/*            <div>{e.message}</div>*/}
                                    {/*        </div>*/}
                                    {/*    </>))}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
        </>
    );
});
