import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useState} from "react";

export const PickColorModal = (props: {open: boolean, setOpen: Function, title: string, description: string, setData: Function}) => {

    const handleOpen = () => props.setOpen(!props.open);
    const [text, setText] = useState(null);
    const save = () => {
        props.setData(text);
        handleOpen();
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>{props.title}</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'mb-5'}>{props.description}</div>
                <div className="space-y-5">
                    <Input type={'color'} size={'lg'} onInput={(e : any) => setText(e.target.value)} onPaste={(e : any) => setText(e.target.value)} labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Wybierz kolor"/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="gradient" color="green" onClick={save}>
                    <span>Zapisz kolor</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
