import {ThemeProvider, Tooltip, Typography} from "@material-tailwind/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "../store/store";
import children = ThemeProvider.propTypes.children;

export const LevelCardPreview = (props: {id: string, children: any}) => {
    const { t, i18n } = useTranslation();
    const store = useStore();
    return (
        <>
            <Tooltip
                className={'bg-[#111111]'}
                placement={'bottom'}
                content={
                    <div className="w-80">
                        <Typography color="white" className="font-medium">
                            <div
                                className={"text-xl font-bold font-montserrat"}>{t('profile.levelCard')}
                            </div>
                        </Typography>
                        <img className={'h-full'} src={`${store.apiURL}/user/${props.id}/card`}/>
                    </div>
                }
            >
                <div>{props.children}</div>
            </Tooltip>
        </>
    );
};
