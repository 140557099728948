import { Button, Spinner } from "@material-tailwind/react";
import { observer } from "mobx-react";
import {Transaction} from "../model/Transaction";
import React from "react";
import {Punishment} from "../model/Punishment";

export const PunishmentItem = observer((props: { punishment: Punishment }) => {
    return (
        <>
            <div className={"flex items-center justify-between"}>
                <div className={"flex flex-col"}>
                    <div
                        className={"text-lg"}>Powód: <span className={"text-gray-500"}>{props.punishment.reason}</span>
                    </div>
                    <div
                        className={"text-lg"}>Moderator: <span
                        className={"text-red-500"}>{props.punishment.moderator_user != null ? props.punishment.moderator_user.username : "nieznany"}</span>
                    </div>
                </div>
                <span
                    className={"text-gray-500 text-sm"}>{props.punishment._id}</span>
            </div>
        </>
    )
});
