import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React from "react";

export const MigrateAccount = (props: {open: boolean, setOpen: Function}) => {

    const handleOpen = () => props.setOpen(!props.open);
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>Rozpocznij proces migracji danych</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'text-red-500 text-xl'}>NOTE!!!</div>
                <div className={'mb-5'}>Ten proces nie ma opcji powrotu wstecz po zatwierdzeniu dane zostaną przepisane na wskazane ID</div>
                <div className="space-y-5">
                    <Input labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Identyfikator użytkownika, którego dane mają zostać przeniesionę"/>
                    <Input labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} crossOrigin={''} label="Identyfikator użytkownika, na którego dane mają zostać przeniesionę"/>
                    <Textarea labelProps={{className: 'text-white'}} className={'text-white'} color={'blue'} label="Powód migracji"/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="gradient" color="red" onClick={handleOpen}>
                    <span>Potwierdź migracje</span>
                </Button>
            </DialogFooter>
        </Dialog>
        </>);
}
