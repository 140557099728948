import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {observer} from "mobx-react";
import {DebugType, parseSnackbarTypeColor} from "../components/model/Snackbar";
import {NoAccess} from "../components/NoAccess";
import {IconButton, Input, Option, Select, Textarea} from "@material-tailwind/react";
import SaveIcon from 'remixicon-react/FullscreenLineIcon';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import PencilIcon from 'remixicon-react/PencilLineIcon';
import {SuccessButton} from "../components/ui/SuccessButton";
import {InsertParamModal} from "../components/modal/InsertParamModal";
import {When} from "react-if";
import {EditButton} from "../components/ui/EditButton";


export const ConfigurationPage: React.FC = observer(() => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [saving, setSave] = useState(false);
    const [mode, setMode] = useState('1');
    const [roles, setRoles] = useState([]);
    const [emoji, setEmoji] = useState([]);
    const [channels, setChannels] = useState([]);
    const [configuration, setConfiguration] : any = useState([]);
    if(store.user == null){
        return <NoAccess/>;
    }
    useEffect(()=> {
        store.getChannels().then(e=> {
            setChannels(e);
        });
        store.getRoles().then(e=> {
            setRoles(e);
        });
        store.getEmojis().then(e=> {
            setEmoji(e);
        })
        store.getConfiguration('784111179479515198').then((n: any) => {
            setConfiguration(n.variables);
        })
        store.setLoading(false);
    }, []);
    const updateConfiguration = (prop: string, value: any) => {
        let em = Object.assign([], configuration);
        em[prop] = value;
        setConfiguration(em);
    }
    const deleteConfiguration = (prop: string) => {
        let em = Object.assign([], configuration);
        delete em[prop]
        setConfiguration(em);
    }
    const type = (value: any) => {
        if(channels.find((d: any)=> d.id == value) !=null){
            return 'Kanał - '+(channels.find((d: any)=> d.id == value) as any).name
        }
        if(roles.find((d: any)=> d.id == value)){
            return 'Rola - '+(roles.find((d: any)=> d.id == value) as any).name;
        }
        if(emoji.find((d: any)=> d.id == value)){
            return 'Emoji serwerowe - '+(emoji.find((d: any)=> d.id == value) as any).name
        }
        if(!isNaN(value)){
            return 'Liczba';
        }
        return 'Tekst'
    }
    const save = () => {
        setSave(true);
        store.updateConfiguration('784111179479515198', configuration).then(e=> {
            store.addSnackbar(
                {
                    message: {
                        en: 'Settings updated',
                        pl: "Settings updated"
                    },
                    type: DebugType.DEBUG,
                    duration: 0,
                    hide: true
                }
            );
            setSave(false);
        });
    }
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center h-full text-xl text-white font-montserrat top-40"}>
                        {(
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-28"}>
                                    <div className={'flex justify-between items-center'}>
                                        <div className={"text-4xl font-bold"}>Konfiguracja serwera</div>
                                        <div className={'flex space-x-2'}>
                                            <Select className={'text-white font-montserrat'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    menuProps={{className: 'bg-black'}}
                                                    color={'blue'}
                                                    value={mode}
                                                    label={'Domyślny rodzaj wyświetlania'}
                                                    onChange={(e: any) => setMode(e)}>
                                                <Option disabled={true} value={'0'}>Podstawowy (Niedostępny)</Option>
                                                <Option value={'1'}>Zaawansowany</Option>
                                            </Select>
                                            <SuccessButton label={'Zapisz'} press={()=> {save()}} loading={saving}/>
                                            <EditButton label={'Utwórz'} press={()=> {setOpen(true)}} loading={false}/>
                                        </div>
                                    </div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <When condition={mode == '0'}>
                                        <div className={'mx-16 rounded-lg p-5 grid grid-cols-3 gap-2'}>
                                            {Object.keys(configuration).map(d =>
                                                <div className={'bg-[#111111] rounded-md p-2 flex justify-between'}
                                                     key={d}>
                                                    <div
                                                        className="flex w-full flex-row items-center p-2">
                                                        <div className={'flex flex-col'}>
                                                            <div className={'flex space-x-1 items-center'}>
                                                                <span className={'text-sm'}>Nazwa:</span>
                                                                <span className={'text-xs text-gray-500'}>{d}</span>
                                                            </div>
                                                            <div className={'flex space-x-1 items-center'}>
                                                                <span className={'text-sm'}>Rodzaj:</span>
                                                                <div
                                                                    className={'text-xs text-gray-500 whitespace-pre-wrap'}>{type(configuration[d])}</div>
                                                            </div>
                                                            <div className={'flex space-x-1 items-center'}>
                                                                <span className={'text-sm'}>Wartość:</span>
                                                                <div
                                                                    className={'text-xs text-gray-500 whitespace-pre-wrap'}>{configuration[d]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <IconButton onClick={() => deleteConfiguration(d)}
                                                                    variant="text" className="rounded-full">
                                                            <CloseIcon color={'red'}/>
                                                        </IconButton>
                                                        <IconButton variant="text" className="rounded-full"
                                                                    onClick={() => updateConfiguration(d, configuration[d] + '\n')}>
                                                            <PencilIcon color={'green'}/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </When>
                                    <When condition={mode == '1'}>
                                        <div className={'mx-16 rounded-lg p-5 grid grid-cols-2 gap-2'}>
                                            {Object.keys(configuration).map(d =>
                                                <div className={'my-4'} key={d}>
                                                    <div className={'text-sm'}>{d}</div>
                                                    <div
                                                        className="flex w-full flex-row items-center gap-2 rounded-[99px] border border-gray-900/10 bg-gray-900/5 p-2">
                                                        <div className="flex">
                                                        <IconButton onClick={() => deleteConfiguration(d)}
                                                                        variant="text" className="rounded-full">
                                                                <CloseIcon color={'red'}/>
                                                            </IconButton>
                                                        </div>
                                                        <When condition={String(configuration[d]).includes('\n')}>
                                                            <Textarea
                                                                onPaste={(e: any) => updateConfiguration(d, e.target.value)}
                                                                onInput={(e: any) => updateConfiguration(d, e.target.value)}
                                                                value={configuration[d]}
                                                                rows={1}
                                                                className="text-white"
                                                                resize={true}
                                                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                            />
                                                        </When>
                                                        <When condition={!String(configuration[d]).includes('\n')}>
                                                            <Input
                                                                crossOrigin={''}
                                                                onPaste={(e: any) => updateConfiguration(d, e.target.value)}
                                                                onInput={(e: any) => updateConfiguration(d, e.target.value)}
                                                                value={configuration[d]}
                                                                multiple={true}
                                                                className="text-white"
                                                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                            />
                                                        </When>
                                                        <div>
                                                            <When
                                                                condition={!String(configuration[d]).includes('\n') && (typeof configuration[d]) == 'string'}>
                                                                <IconButton variant="text" className="rounded-full"
                                                                            onClick={() => updateConfiguration(d, configuration[d] + '\n')}>
                                                                    <SaveIcon color={'green'}/>
                                                                </IconButton>
                                                            </When>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </When>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <InsertParamModal open={open} setOpen={setOpen} setData={(o: any) => updateConfiguration(o.prop, o.value)}/>
        </>
    );
});
