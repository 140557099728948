import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input, Option, Select,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import {useStore} from "../../store/store";
import {useTranslation} from "react-i18next";
import {BadgeItem} from "../BadgeItem";

export const SelectBadgeHistory = (props: {open: boolean, setOpen: Function, add: Function}) => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [badges, setBadges] = useState([]);
    useEffect(()=> {
        setBadges(store.history.badges);
    });
    const addMe = (data: any) => {
        props.add(data);
        handleOpen();
    }
    const handleOpen = () => props.setOpen(!props.open);
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>Wybierz odznakę z twojej historii</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className="space-y-5">
                    <Select className={'text-white'}
                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                            menuProps={{className: 'bg-black hover:bg-gray-900'}}
                            color={'blue'}
                            label={'Wybierz odznakę'}
                            onChange={(e: any) => addMe(e)}
                            selected={(element) =>
                                element &&
                                React.cloneElement(element, {
                                    disabled: true,
                                    className:
                                        "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                                })
                            }>
                        {badges.map((e: any)=> <Option key={e.emoji} value={e} className={'flex items-center gap-2'}><BadgeItem badge={e} debug={true}/></Option>)}
                    </Select>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
