import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {observer} from "mobx-react";
import {Switch} from "@material-tailwind/react";

const NoAccess = React.lazy(async () => ({ default: (await import('../components/NoAccess')).NoAccess }))
const RankCard = React.lazy(async () => ({ default: (await import('../components/RankCard')).RankCard }))
const SuccessButton = React.lazy(async () => ({ default: (await import('../components/ui/SuccessButton')).SuccessButton }))

export const SettingsPage: React.FC = observer(() => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [saving, setSaving] = useState(false);
    let [profileEdit, setProfileEdit]: any = useState({})
    if(store.user == null){
        return <NoAccess/>;
    }
    useEffect(() => {
        store.setLoading(true);
        const card = {
            background_color: store.user.card.background_color,
            background_image: store.user.card.background_image,
            primary_text_color: store.user.card.primary_text_color,
            secondary_text_color: store.user.card.secondary_text_color,
            progress_fill_color: store.user.card.progress_fill_color,
            progress_tint_color: store.user.card.progress_tint_color,
        }
        setProfileEdit(card);
        store.setLoading(false);
    }, []);
    const saveProfileCard = () => {
        setSaving(true);
        store.saveLeavelCard(profileEdit).then((e) => {
            if(e !=null){
                let user = store.user;
                user.card = profileEdit;
                store.setUser(user);
            }
            setSaving(false);
        })
    }
    const updateProfile = (prop: string, value: string) => {
        let card = Object.assign({}, profileEdit);
        card[prop] = value;
        setProfileEdit(card)
    }
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center h-full text-xl text-white font-montserrat top-40"}>
                        {(
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-28"}>
                                    <div className={"text-4xl font-bold"}>{t("settings.name")}</div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={"grid grid-cols-1 xl:grid-cols-2 xl:gap-10"}>
                                        <div className={"grid grid-cols-2 gap-2 xl:p-10 rounded-2xl"}>
                                            <div className={"col-start-1 col-end-3 mb-5"}>
                                                <div
                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold"}>
                                                    <div>{t("settings.customize")}</div>
                                                </div>
                                                <div
                                                    className={"text-sm xl:text-lg text-gray-500 font-montserrat"}>
                                                    <div>{t("settings.customizeDetails")}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    className="block text-sm mb-2">{t("settings.fillColor")}</label>
                                                <input type="color"
                                                       className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                                       value={profileEdit.background_color} title="Choose your color"
                                                       onChange={e => {
                                                           updateProfile('background_color', e.target.value);
                                                       }}/>
                                            </div>
                                            <div>
                                                <label
                                                    className="block text-sm mb-2">{t("settings.firstTextColor")}</label>
                                                <input type="color"
                                                       className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                                       value={profileEdit.primary_text_color} title="Choose your color"
                                                       onChange={e => {
                                                           updateProfile('primary_text_color', e.target.value);
                                                       }}/>
                                            </div>
                                            <div>
                                                <label
                                                    className="block text-sm mb-2">{t("settings.secondaryTextColor")}</label>
                                                <input type="color"
                                                       className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                                       value={profileEdit.secondary_text_color}
                                                       title="Choose your color"
                                                       onChange={e => {
                                                           updateProfile('secondary_text_color', e.target.value);
                                                       }}/>
                                            </div>
                                            <div>
                                                <label
                                                    className="block text-sm mb-2">{t("settings.progressFillColor")}</label>
                                                <input type="color"
                                                       className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                                       value={profileEdit.progress_fill_color}
                                                       title="Choose your color"
                                                       onChange={e => {
                                                           updateProfile('progress_fill_color', e.target.value);
                                                       }}/>
                                            </div>
                                            <div>
                                                <label
                                                    className="block text-sm mb-2">{t("settings.progressTintColor")}</label>
                                                <input type="color"
                                                       className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                                                       value={profileEdit.progress_tint_color}
                                                       title="Choose your color"
                                                       onChange={e => {
                                                           updateProfile('progress_tint_color', e.target.value);
                                                       }}/>
                                            </div>
                                            <div className={"col-start-1 col-end-3 mt-5 flex items-end justify-end"}>
                                                <SuccessButton label={'Zapisz'} press={() => {
                                                    saveProfileCard()
                                                }} loading={saving}/>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className={"text-2xl xl:text-4xl mt-5 xl:mt-10 font-montserrat font-bold"}>{t('settings.cardPreview')}
                                            </div>
                                            <div
                                                className={"text-sm xl:text-lg font-montserrat text-gray-500 mb-5"}>{t('settings.cardPreviewDetails')}
                                                <a
                                                    className={"text-white font-bold"}
                                                    href={"https://discord.com/channels/784111179479515198/819902373354930236"}>💰┃ekonomia</a>
                                            </div>
                                            <RankCard profileEdit={profileEdit}/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-4xl font-bold"}>{t("settings.loginOptions.title")}</div>
                                        <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                        <div className={'flex items-center justify-between'}>
                                            <div className={'flex flex-col'}>
                                                <div className={'font-bold text-lg'}>{t("settings.loginOptions.name")}</div>
                                                <div className={'text-gray-500 text-sm'}>{t("settings.loginOptions.description")}</div>
                                            </div>
                                            <div>
                                                <Switch color={'red'} crossOrigin={''} checked={store.fastLogin} onChange={(e)=> store.setFastLogin(e.target.checked)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </>
    );
});
