import {observer} from "mobx-react";
import React from "react";
import PlusIcon from "remixicon-react/AddCircleLineIcon";
import HistoryLineIcon from 'remixicon-react/HistoryLineIcon'
import {Tooltip} from "@material-tailwind/react";
export const CalendarDay = observer((props: {day: Date, changeCurrentDay: Function, plannedMessage: [], channels: [], setData: Function}) => {
    const firstDayOfMonth = new Date(props.day.getFullYear(), props.day.getMonth(), 0);
    const weekDay = firstDayOfMonth.getDay();
    let currentDays = [];
    for (let day = 0; day < 42; day++) {
        if(day == 0){
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() - (weekDay-1));
        }else{
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }
        let calendarDay = {
            currentMonth: (props.day.getMonth() === new Date(firstDayOfMonth).getMonth()),
            date: new Date(firstDayOfMonth),
            isToday: new Date(firstDayOfMonth).getDate() == new Date().getDate() && new Date(firstDayOfMonth).getMonth() == new Date().getMonth() && new Date(firstDayOfMonth).getFullYear() == new Date().getFullYear(),
            isBefore: (
                (
                    new Date(firstDayOfMonth).getDate() < new Date().getDate() &&
                    new Date(firstDayOfMonth).getMonth() <= new Date().getMonth() &&
                    new Date(firstDayOfMonth).getFullYear() <= new Date().getFullYear()
                ) ||
                (
                    new Date(firstDayOfMonth).getMonth() < new Date().getMonth() &&
                    new Date(firstDayOfMonth).getFullYear() <= new Date().getFullYear()
                )
            ),
            nextMonth: (
                (
                    new Date(firstDayOfMonth).getMonth() >= new Date().getMonth() && new Date(firstDayOfMonth).getFullYear() >= new Date().getFullYear()
                ) ||
                (
                    new Date(firstDayOfMonth).getFullYear() >= new Date().getFullYear()
                )
            ),
            month: new Date(firstDayOfMonth).getMonth(),
            number: new Date(firstDayOfMonth).getDate(),
            channels: props.channels,
            selected: (firstDayOfMonth.toDateString() === props.day.toDateString()),
            year: firstDayOfMonth.getFullYear(),
            messages: props.plannedMessage.filter((e: any) => {
                return new Date(Date.parse(e.deliveryDate)).getDate() == firstDayOfMonth.getDate() && new Date(e.deliveryDate).getMonth() == firstDayOfMonth.getMonth() && new Date(e.deliveryDate).getFullYear() == firstDayOfMonth.getFullYear();
            })
        }
        currentDays.push(calendarDay);
    }
    console.table(currentDays)


    return (
        <div className="grid grid-cols-7 gap-2">
            {
                currentDays.map((day: any, index: any) => {
                    return (
                        <div key={`${index}`}
                             className={`rounded-lg flex flex-col items-center justify-between bg-[#1111114a] p-3 max-h-60 ${day.isToday ? 'border border-white' : ''} ${day.isBefore ? 'cursor-not-allowed' : ''}`}>
                            <div
                                className={`text-center text-sm space-x-2 ${!day.currentMonth ? 'text-gray-500' : ''} ${day.isBefore ? 'text-red-500' : 'font-bold'}`}>
                                <span>{day.number}</span>
                            </div>
                            {day.messages.length > 0 ? (<>
                                <div className={'space-y-2 my-2 w-full'}>
                                    {day.messages.slice(0, 2).map((d: any) => (<>
                                        <div key={d.id} className={'text-xs bg-[#ff00002a] w-full rounded-lg p-1 px-4'}>
                                            <div
                                                className={'font-black'}>{new Date(d.deliveryDate).getHours()}:{("0" + new Date(d.deliveryDate).getMinutes()).slice(-2)}</div>
                                            <div>{(props.channels.find((e: any) => e.id === d.channel) as any).name}</div>
                                        </div>
                                    </>))}
                                </div>
                            </>) : <>
                                <div className={'text-center text-xs text-gray-500'}>{day.isBefore ? 'To jest już przeszłość :(' : 'Nic nie zaplanowano'}</div>
                            </>}
                            <div className={'space-x-2 flex items-center'}>
                                {day.messages.length >= 1 ? (<>
                                    <Tooltip content={'Zobacz więcej'} placement={'bottom-start'}>
                                        <div onClick={()=> props.setData(day)}  className={'cursor-pointer'}><HistoryLineIcon color={'orange'}/></div>
                                    </Tooltip>
                                </>) : <></>}
                                {day.isBefore ? (<>

                                </>) : <><Tooltip content={'Zaplanuj wiadomość w ten dzień'} placement={'bottom-start'}>
                                    <div onClick={()=> props.changeCurrentDay(day.date)} className={'cursor-pointer'}><PlusIcon color={'green'}/></div>
                                </Tooltip></>}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
});
