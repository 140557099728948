import { redirect, useNavigate } from "react-router";
import { useStore } from "../../store/store";
import logo from "../../assets/banner.png";
import plFlag from "../../assets/flag_pl.png";
import gbpFlag from "../../assets/flag_gbp.png";
import { useTranslation } from "react-i18next";
import {Else, If, Then, When} from "react-if";
import React from "react";
import CogIcon from 'remixicon-react/Settings4FillIcon'
import LogoutIcon from 'remixicon-react/LogoutBoxRFillIcon'
import MenuIcon from 'remixicon-react/Menu5FillIcon'
import BugIcon from 'remixicon-react/Bug2FillIcon'
import TicketIcon from 'remixicon-react/TicketFillIcon'
import EditIcon from 'remixicon-react/EditCircleFillIcon'
import ConfigurationIcon from 'remixicon-react/Settings4LineIcon'
import Earth from 'remixicon-react/EarthFillIcon'
import DeveloperIcon from 'remixicon-react/ToolsFillIcon'
import InfoIcon from 'remixicon-react/InformationLineIcon'
import {Button, Menu, MenuHandler, MenuItem, MenuList, Tooltip} from "@material-tailwind/react";
import {observer} from "mobx-react";
import {DebugType} from "../model/Snackbar";
import PlannedIcon from "remixicon-react/ChatForwardLineIcon";
import ConsoleIcon from "remixicon-react/TerminalWindowLineIcon";
import config from '../../config.json';


export const LandingNavbar = observer((props: {}) => {
  const store = useStore();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const goTo = (link: string) => {
    window.open(link, "_blank");
  };
  const auth = () => {
      //@ts-ignore
    window.location.href=window.GENESISZONE.config.loginURL+'?useFastLogin='+store.fastLogin;
  };
  const changeLanguageHandler = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("pl");
      store.setLanguage("pl");
    } else {
      i18n.changeLanguage("en");
        store.setLanguage("en");
    }
  };
  const devComponent = () => {
      if(store.user && (store.user.permission & 2) === 2){
          return <>
              <Menu>
                  <MenuHandler>
                      <div
                          className="cursor-pointer flex items-center"
                      >
                          <DeveloperIcon/>
                      </div>
                  </MenuHandler>
                  <MenuList className={'bg-black text-white font-montserrat '}>
                      <MenuItem className={'hover:bg-gray-900 hover:text-white'} onClick={()=>{navigate({pathname: '/ticket-analytic'})}}><div className={'flex items-center'}><TicketIcon/><span className={'ml-2'}>{t('menu.ticket')}</span></div></MenuItem>
                      <When condition={(store.user.permission & 8388608) === 8388608}>
                          <MenuItem className={'hover:bg-gray-900 hover:text-white'} onClick={()=>{navigate({pathname: '/manage-user'})}}><div className={'flex items-center'}><EditIcon/><span className={'ml-2'}>{t('menu.user')}</span></div></MenuItem>
                      </When>
                      <When condition={(store.user.permission & 1073741824) === 1073741824}>
                          <MenuItem className={'hover:bg-gray-900 hover:text-white'} onClick={()=>{navigate({pathname: '/planned-message'})}}><div className={'flex items-center'}><PlannedIcon/><span className={'ml-2'}>{t('menu.message')}</span></div></MenuItem>
                      </When>
                      <When condition={(store.user.permission & 268435456) === 268435456}>
                          <MenuItem className={'hover:bg-gray-900 hover:text-white'} onClick={()=>{navigate({pathname: '/configuration'})}}><div className={'flex items-center'}><ConfigurationIcon/><span className={'ml-2'}>{t('menu.configuration')}</span></div></MenuItem>
                      </When>
                      <When condition={(store.user.permission & 536870912) === 536870912}>
                          <MenuItem className={'hover:bg-gray-900 hover:text-white'} onClick={()=>{navigate({pathname: '/developer'})}}><div className={'flex items-center'}><DeveloperIcon/><span className={'ml-2'}>{t('menu.console')}</span></div></MenuItem>
                      </When>
                      <MenuItem disabled={true} className={'hover:bg-gray-900 hover:text-white'}><div className={'flex items-center'}><InfoIcon/><span className={'ml-2'}>Wersja: {config.version} (#{config.build})</span></div></MenuItem>
                      <MenuItem disabled={true} className={'hover:bg-gray-900 hover:text-white'}><div className={'flex items-center'}><InfoIcon/><span className={'ml-2'}>Uprawnienia: {store.user.permission}</span></div></MenuItem>
                  </MenuList>
              </Menu>
          </>
      }
  }
  return (
      <>
          <nav
              className="flex bg-black text-white justify-between px-10 h-full content-center">
              <div className="font-montserrat flex items-center justify-between w-full">
                  <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => {
                          navigate({pathname: '/'})
                      }}
                  >
                      <img src={logo} alt="Logo" className="h-10 xl:h-14"/>{" "}
                  </div>
                  <div className="hidden md:flex flex-row space-x-4">
                      {store.existUser() ? (<div className={"flex space-x-4 items-center"}>
                          <Tooltip
                              content={t('menu.profile')}
                              animate={{
                                  mount: {scale: 1, y: 0},
                                  unmount: {scale: 0, y: 25},
                              }}
                          >
                              <img className={"h-14 rounded-full cursor-pointer"}
                                   onClick={() => {
                                       navigate({pathname: '/profile/' + store.user.id})
                                   }}
                                   src={`https://cdn.discordapp.com/avatars/${store.user.id}/${store.user.avatar}`}
                                   alt={"Avatar"}/>
                          </Tooltip>
                          <div className={"flex flex-col"}>
                              <div className={"font-bold text-lg"}>{store.user.global_name}</div>
                              <div className={"text-gray-500 text-sm -mt-2"}>{store.user.username}</div>
                          </div>
                      </div>) : (<Tooltip
                          content="Zaloguj się przez platformę Discord"
                          animate={{
                              mount: {scale: 1, y: 0},
                              unmount: {scale: 0, y: 25},
                          }}
                      >
                          <div
                              className="cursor-pointer"
                              onClick={() => auth()}
                          >
                              {t("button.login")}
                          </div>
                      </Tooltip>)}
                      <Tooltip
                          content={t('menu.language')}
                          animate={{
                              mount: {scale: 1, y: 0},
                              unmount: {scale: 0, y: 25},
                          }}
                      >
                          <div
                              className="cursor-pointer flex items-center"
                              onClick={() => changeLanguageHandler()}
                          >
                              <img
                                  src={i18n.language === "pl" ? plFlag : gbpFlag}
                                  alt="Flag icon"
                                  className="h-4 w-7"
                              />
                          </div>
                      </Tooltip>
                      {devComponent()}
                      <If condition={store.user != null}>
                          <Then>
                              <Tooltip
                                  content={t('menu.settings')}
                                  animate={{
                                      mount: {scale: 1, y: 0},
                                      unmount: {scale: 0, y: 25},
                                  }}
                              >
                                  <div
                                      className="cursor-pointer flex items-center"
                                      onClick={() => {
                                          navigate({pathname: '/settings'})
                                      }}
                                  >
                                      <CogIcon/>
                                  </div>
                              </Tooltip>
                              <Tooltip
                                  content={t('menu.bug')}
                                  animate={{
                                      mount: {scale: 1, y: 0},
                                      unmount: {scale: 0, y: 25},
                                  }}
                              >
                                  <div
                                      className="cursor-pointer flex items-center"
                                      onClick={() => {
                                          store.addSnackbar({
                                              message: {
                                                  pl: "Ups! Ta funkcja nie jest jeszcze dostępna",
                                                  en: 'Sorry! This function is not ready yet'
                                              },
                                              type: DebugType.WARN,
                                              duration: 10000
                                          })
                                      }}
                                  >
                                      <BugIcon/>
                                  </div>
                              </Tooltip>
                              <Tooltip
                                  content={t('menu.logout')}
                                  animate={{
                                      mount: {scale: 1, y: 0},
                                      unmount: {scale: 0, y: 25},
                                  }}
                              >
                                  <div
                                      className="cursor-pointer flex items-center text-red-500"
                                      onClick={() => {
                                          navigate({pathname: '/'})
                                          store.logout();
                                      }}
                                  >
                                      <LogoutIcon/>
                                  </div>
                              </Tooltip>
                          </Then>
                      </If>
                  </div>

                  <div className="md:hidden">
                      <button
                          className="flex items-center justify-center px-4 py-2 text-xs font-bold border border-white rounded-full hover:bg-white hover:text-black"
                          onClick={() => {
                              store.showAsideToggle();
                          }}
                      >
                          <MenuIcon/>
                      </button>
                  </div>
              </div>
          </nav>
          <If condition={store.showAside}>
              <Then>
                  <div
                      className={"h-auto bg-black shadow-2xl opacity-95 text-white w-screen flex items-center z-10 relative justify-start"}>
                      <div className={"p-5 h-[100%] space-y-5 text-xl font-sans "}>
                          {store.existUser() ? (<div className={"flex items-center"}>
                              <Tooltip
                                  content={t('menu.profile')}
                                  animate={{
                                      mount: {scale: 1, y: 0},
                                      unmount: {scale: 0, y: 25},
                                  }}
                              >
                                  <img className={"h-8 rounded-full cursor-pointer"}
                                       onClick={() => {
                                           navigate({pathname: '/profile/' + store.user.id})
                                       }}
                                       src={`https://cdn.discordapp.com/avatars/${store.user.id}/${store.user.avatar}`}
                                       alt={"Avatar"}/>
                              </Tooltip>
                              <div className={"flex flex-col ml-3"}>
                                  <div className={"font-bold text-xl"}>{store.user.username}</div>
                                  <div className={"text-gray-500 text-sm"}>{store.user.global_name}</div>
                              </div>
                          </div>) : (<Tooltip
                              content="Zaloguj się przez platformę Discord"
                              animate={{
                                  mount: {scale: 1, y: 0},
                                  unmount: {scale: 0, y: 25},
                              }}
                          >
                              <div
                                  className="cursor-pointer flex"
                                  onClick={() => auth()}
                              >
                                  <LogoutIcon/>
                                  <div className={"ml-5"}>{t("button.login")}</div>
                              </div>
                          </Tooltip>)}
                          <div
                              className="cursor-pointer text-white flex flex-row items-center"
                              onClick={() => changeLanguageHandler()}
                          >
                              <img
                                  src={i18n.language === "pl" ? plFlag : gbpFlag}
                                  alt="Flag icon"
                                  className="h-4 w-7 mr-4"
                              />
                              <div className={'flex flex-col items-start'}>
                                  <div>{t('menu.language')}</div>
                                  <div className={"text-gray-500 text-xs"}>{t("language." + i18n.language)}</div>
                              </div>
                          </div>
                          <If condition={store.user != null}>
                              <Then>
                                  <Tooltip
                                      content={t('menu.settings')}
                                      animate={{
                                          mount: {scale: 1, y: 0},
                                          unmount: {scale: 0, y: 25},
                                      }}
                                  >
                                      <div
                                          className="cursor-pointer flex items-center"
                                          onClick={() => {
                                              navigate({pathname: '/settings'})
                                          }}
                                      >
                                          <CogIcon/>
                                          <span className={"ml-4"}>{t('menu.settings')}</span>
                                      </div>
                                  </Tooltip>
                                  <Tooltip
                                      content={t('menu.bug')}
                                      animate={{
                                          mount: {scale: 1, y: 0},
                                          unmount: {scale: 0, y: 25},
                                      }}
                                  >
                                      <div
                                          className="cursor-pointer flex items-center"
                                          onClick={() => {
                                              store.addSnackbar({
                                                  message: {
                                                      pl: "Ups! Ta funkcja nie jest jeszcze dostępna",
                                                      en: 'Sorry! This function is not ready yet'
                                                  },
                                                  type: DebugType.WARN,
                                                  duration: 10000
                                              })
                                          }}
                                      >
                                          <BugIcon/>
                                          <span className={"ml-4"}>{t('menu.bug')}</span>
                                      </div>
                                  </Tooltip>
                                  <Tooltip
                                      content={t('menu.logout')}
                                      animate={{
                                          mount: {scale: 1, y: 0},
                                          unmount: {scale: 0, y: 25},
                                      }}
                                  >
                                      <div
                                          className="cursor-pointer flex items-center text-red-500"
                                          onClick={() => {
                                              navigate({pathname: '/'})
                                              store.logout();
                                          }}
                                      >
                                          <LogoutIcon/>
                                          <span className={"ml-4"}>{t('menu.logout')}</span>
                                      </div>
                                  </Tooltip>
                              </Then>
                          </If>
                      </div>
                  </div>
              </Then>
          </If>
      </>
  );
});
