export function parseMonth(month: number): string {
    const months = [
        "Styczen",
        "Luty",
        "Marzec",
        "Kwiecien",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpien",
        "Wrzesien",
        "Pazdziernik",
        "Listopad",
        "Grudzien",
    ];
    return months[month-1];
}


export function wait(milliseconds : number) {
    return new Promise(resolve => setTimeout(resolve, 1000 * milliseconds));
}

export function toBase64(arr : any) {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
        arr.reduce((data : any, byte: any) => data + String.fromCharCode(byte), '')
    );
}