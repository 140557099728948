import { Button, Spinner } from "@material-tailwind/react";
import { observer } from "mobx-react";
import {Transaction} from "../model/Transaction";
import React from "react";
import {Punishment} from "../model/Punishment";

export const PunishmentItemAnimation = observer(() => {
    return (
        <>
            <div className={"flex items-center justify-between animate-pulse w-full"}>
                <div className={"flex flex-col w-full"}>
                    <div
                        className={"h-4 bg-gray-800 mt-2 rounded w-1/2"}>
                    </div>
                    <div
                        className={"h-4 bg-gray-800 mt-2 rounded w-1/2"}>
                    </div>
                </div>
                <span
                    className={"h-3 bg-gray-800 mt-2 rounded w-24"}></span>
            </div>
        </>
    )
});
