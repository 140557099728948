import { Button, Spinner } from "@material-tailwind/react";
import { observer } from "mobx-react";
import {Transaction} from "../model/Transaction";
import React from "react";

export const TransactionItem = observer((props: { transaction: Transaction }) => {
    return (
        <div className={"flex items-center justify-between"}>
            <div className={"flex flex-col"}>
                <div className={"text-lg"}>{props.transaction.name}</div>
                <div
                    className={"text-xs text-gray-500"}>{props.transaction.description}</div>
            </div>
            <div className={"text-red-500 flex items-center"}>-{props.transaction.price}
                <img className={"h-7 ml-2"}
                     src={"https://cdn.discordapp.com/emojis/865516630766518292.webp?size=96&quality=lossless"}/>
            </div>

        </div>
    )
});
