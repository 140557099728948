import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {observer} from "mobx-react";
import {parseSnackbarTypeColor} from "../components/model/Snackbar";
import {NoAccess} from "../components/NoAccess";


export const DeveloperPage: React.FC = observer(() => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    if(store.user == null){
        return <NoAccess/>;
    }
    useEffect(()=> {
        store.setLoading(false);
    }, [])
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center h-full text-xl text-white font-montserrat top-40"}>
                        {(
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-28"}>
                                    <div className={"text-4xl font-bold"}>Konsola developera</div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={'mx-16 rounded-lg p-5'}>
                                        {store.snackbar.map((e : any)=> (<>
                                            <div className={'flex flex-col'}>
                                                <div className={'flex flex-row space-x-2 items-center'}>
                                                    <div className={`${parseSnackbarTypeColor(e.type)} rounded-lg text-xs p-2`}>{e.type}</div>
                                                    <div className={'text-sm text-gray-200'}>{new Date(e.timestamp).toLocaleTimeString()}</div>
                                                </div>
                                                <div>{e.message[i18n.language]}</div>
                                            </div>
                                        </>))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </>
    );
});
