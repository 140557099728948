import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { pl } from "react-day-picker/locale";
import {observer} from "mobx-react";
import {Input, Option, Popover, PopoverContent, PopoverHandler, Select} from "@material-tailwind/react";
import { JSX } from "react/jsx-runtime";
import {DayPicker} from "react-day-picker";
import {format} from "date-fns";
import {When} from "react-if";
import {useNavigate, useParams} from "react-router";
import {EditButton} from "../components/ui/EditButton";

const NoAccess = React.lazy(async () => ({ default: (await import('../components/NoAccess')).NoAccess }))
const PlannedMessageComponent = React.lazy(async () => ({ default: (await import('../components/PlannedMessage')).PlannedMessageComponent }))
const MessageVisualization = React.lazy(async () => ({ default: (await import('../components/message/MessageVisualization')).MessageVisualization }))
const SuccessButton = React.lazy(async () => ({ default: (await import('../components/ui/SuccessButton')).SuccessButton }))

export const PlannedMessageEdition: React.FC = observer(() => {
    const store = useStore();
    let params = useParams();
    const [date, setDate] = React.useState<Date>();
    const navigate = useNavigate();
    const [time, setTime] = useState('00:00');
    const [embed2, setEmbed2] :any = useState({
        "content" : undefined,
        "embed": undefined,
        attachments: [],
        reactions: [],
        channel: undefined,
        link: undefined,
        isFetch: false,
    });const [channels, setChannels] : any[] = useState([])
    useEffect(() => {
        store.setLoading(true)
        setDate(new Date())
        if(channels.length == 0){
            store.getChannels().then(e => {
                e.sort((e: any, d: any) => e.position - d.position);
                setChannels(e);
            }).finally(() => {
                store.getPlannedMessage(params.id!).then(e=> {
                    const date = new Date(e.deliveryDate);
                    setDate(date);
                    setTime(date.getHours()+":"+(date.getMinutes() == 0 ? '00' : date.getMinutes().toString()));
                    let data = Object.assign({}, embed2);
                    data.content = e.content;
                    data.embed = e.embed;
                    data.attachments= e.attachments;
                    data.channel = e.channel;
                    data.reactions = e.reactions;
                    data.link = e.link;
                    data.isFetch = true;
                    setEmbed2(data);
                }).finally(()=> {
                    store.setLoading(false);
                })
            });
        }
    }, [channels.length == 0]);
    const setChannel = (id: string) => {
        let card = Object.assign({}, embed2);
        card.channel = id;
        setEmbed2(card);
        console.log(card.channel);
    }
    const updateAttachments = (attachments: any[]) => {
        let card = Object.assign({}, embed2);
        card.attachments = attachments;
        setEmbed2(card);
    }
    const updateReactions = (reactions: any[]) => {
        let card = Object.assign({}, embed2);
        card.reactions = reactions;
        setEmbed2(card);
    }
    const save = () => {
        let local = new Date();
        local.setHours(Number(time.split(":")[0]), Number(time.split(":")[1]));
        local.setDate(date!.getDate())
        local.setMonth(date!.getMonth())
        local.setFullYear(date!.getFullYear())
        setDate(local);
        console.log(date)
        store.editPlannedMessage(params.id!,{
            content: embed2.content,
            embed: embed2.embed,
            deliveryDate: local,
            channel: embed2.channel!,
            attachments: embed2.attachments,
            reactions: embed2.reactions,
            link: embed2.link,
        }).then(()=> {
            navigate({pathname: '/planned-message'})
        })
    }
    const updateMessage = (value: string) => {
        let card = Object.assign({}, embed2);
        card.content = value;
        setEmbed2(card)
    }
    const updateLink = (value: string) => {
        let card = Object.assign({}, embed2);
        card.link = value;
        setEmbed2(card)
    }
    const updateMessageEmbed = (prop?: string, value?: string, subTree?: string) => {
        console.log(subTree, prop, value)
        let card = Object.assign({}, embed2);
        if(prop == null && !value && !subTree){
            card.embed = undefined;
        }else if((value !=null && prop !=null) || value ==null && prop !=null){
            if(!card.embed) card.embed = {};
            if(subTree !=null){
                let subtreedata = Object.assign({}, card.embed[subTree]);
                subtreedata[prop!] = value;
                card.embed[subTree] = subtreedata;
            }else {
                card.embed[prop!] = value;
            }
        }else {
            if(prop == null){
                card.embed = undefined;
            }else {
                card.embed = {};
            }
        }
        setEmbed2(card)
    }
    const buildOptions = () => {
        let option: JSX.Element[] = [];
        const d = channels.filter((e: any) => e.type == 4);
        d.forEach((e: any)=> {
            option.push(<Option key={e.id} value={e.id} disabled={true}>{e.name}</Option>)
            const m = channels.filter((x:any) => x.parent_id === e.id);
            m.sort((e :any,d :any) => e.position - d.position );
            m.map((e:any) => option.push(<Option key={e.id} value={e.id}>{e.name}</Option>))
        })
        return option;
    }
    if(!embed2.isFetch || store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {(store.user.permission & 1073741824) === 1073741824 ? (
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-48"}>
                                    <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Edycja planowanej wiadomości
                                    </div>
                                    <div className={'text-red-500 text-xl font-bold mt-5'}>DEV NOTE</div>
                                    <div className={'text-sm'}>Ta funkcja jest w trakcie rozwoju wszelkie błędy proszę
                                        bezpośrednio zgłaszać do mysterdead przez platformę Discord
                                    </div>
                                    <div className={'flex space-x-1 text-xs'}>
                                        <span>Ta strona obsługuje uprawnienie</span>
                                        <span className={'font-bold'}>1073741824</span>
                                    </div>
                                    <div className={'space-y-5 xl:space-y-10 mt-10'}>
                                        <div className={'grid grid-cols-1 xl:grid-cols-3 gap-2'}>
                                            <div>
                                                <Select className={'text-white'}
                                                        labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                        color={'blue'}
                                                        value={embed2.channel!}
                                                        label={'Wybierz kanał do wysyłki'}
                                                        onChange={(e: any) => setChannel(e)}>
                                                    {buildOptions()}
                                                </Select>
                                            </div>
                                            <Popover placement="bottom">
                                                <PopoverHandler>
                                                    <Input
                                                        className={'text-white'}
                                                        labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                        label="Wybierz date"
                                                        crossOrigin={''}
                                                        value={date ? format(date, "PPP", {locale: pl}) : ""}
                                                    />
                                                </PopoverHandler>
                                                <PopoverContent className={'bg-[#111111]'}>
                                                    <DayPicker
                                                        mode="single"
                                                        defaultMonth={new Date()}
                                                        startMonth={new Date()}
                                                        endMonth={new Date(new Date().getUTCFullYear(), new Date().getUTCMonth()+3)}
                                                        locale={pl}
                                                        selected={date}
                                                        timeZone="Europe/Warsaw"
                                                        onSelect={setDate}
                                                        showWeekNumber
                                                        showOutsideDays={false}
                                                        disabled={{before: new Date()}}
                                                        className="border-0"
                                                        footer={
                                                            date
                                                                ? `You picked ${date.toLocaleDateString()}.`
                                                                : "Please pick a date."
                                                        }
                                                        classNames={{
                                                            caption: "flex justify-center py-2 mb-4 relative items-center",
                                                            caption_label: "text-white font-bold text-2xl",
                                                            nav: "flex items-center justify-center",
                                                            nav_button:
                                                                "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                                            nav_button_previous: "absolute left-1.5",
                                                            nav_button_next: "absolute right-1.5",
                                                            day: "h-9 w-9 text-center p-0 font-normal",
                                                            today: `rounded-md bg-gray-200 text-gray-900`, // Add a border to today's date
                                                            selected: `rounded-md bg-gray-800 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white`, // Highlight the selected day
                                                            root: `shadow-lg p-5`, // Add a shadow to the root element
                                                            chevron: ` fill-amber-500 space-y-5 select-none` // Change the color of the chevron
                                                        }}
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                            <Input
                                                className={'text-white'}
                                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                label="Wybierz czas"
                                                type={'time'}
                                                min={'00:00'}
                                                max={'23:59'}
                                                value={time}
                                                onInput={(e: any) => setTime(e.target.value)}
                                                crossOrigin={''}
                                            />
                                        </div>
                                        <div className={'grid grid-cols-1 xl:grid-cols-2 gap-2'}>
                                            <MessageVisualization edit={true} data={embed2} updateEmbed={updateMessageEmbed}
                                                                  updateContent={updateMessage} updateAttachments={updateAttachments} updateReactions={updateReactions} updateLink={updateLink}/>
                                            <div>
                                                {/*<pre>*/}
                                                {/*    {JSON.stringify(embed2, null, 2)}*/}
                                                {/*</pre>*/}
                                                <div>Edycja zdjęć: <span className={'text-red-500'}>Niedostępna</span></div>
                                                <When condition={embed2.channel != null}>
                                                    <div className={'flex space-x-2 items-center'}>
                                                        <span>Twoja wiadomość pojawi się na kanale </span>
                                                        <a href={`https://discord.com/channels/784111179479515198/${embed2.channel}`}
                                                           className={'font-bold text-blue-500'}>{channels.find((e: any) => e.id == embed2.channel)?.name}</a>
                                                    </div>
                                                </When>
                                                <When condition={embed2.channel != null && date != null}>
                                                    <div className={'flex space-x-2 items-center'}>
                                                        <span>Zostanie ona upubliczniona dnia </span>
                                                        <span
                                                            className={'font-bold'}>{date?.toLocaleDateString()}</span>
                                                    </div>
                                                </When>
                                                <When condition={embed2.channel != null && date != null}>
                                                    <div className={'flex space-x-2 items-center'}>
                                                        <span>I wysłana o godzinię </span>
                                                        <span className={'font-bold'}>{time}</span>
                                                    </div>
                                                </When>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={'flex items-end justify-end mt-10'}>
                                        <EditButton label={'Edytuj'} press={() => {
                                            save()
                                        }} loading={false}/>
                                    </div>
                                </div>
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
        </>
    );
});
