import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input, Option, Select,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import {useStore} from "../../store/store";
import {useTranslation} from "react-i18next";

export const AddBadge = (props: {open: boolean, setOpen: Function, add: Function}) => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [badges, setBadges] = useState([]);
    const [badge, setBadge] = useState({
        emoji: '',
        emoji_name: '',
        name: {
            en: '',
            pl: '',
        },
        type: 'COMMON'
    })
    useEffect(()=> {
        if(badges.length === 0){
            store.getEmojis().then(e=> {
                setBadges(e);
            })
        }
    }, []);
    const addMe = (data: any) => {
        props.add(data);
        handleOpen();
    }
    const selectEmoji = (emojiId: string) => {
        const em: any = badges.find((e: any)=> e.id === emojiId);
        let data : any = Object.assign({}, badge);
        data['emoji'] = em.id;
        data['emoji_name'] = em.name;
        setBadge(data);
    }
    const updateState = (param: string, value: string, tree?: string) => {
        let data : any = Object.assign({}, badge);
        if(tree !=null){
            data[tree][param] = value;
        }else{
            data[param] = value;
        }
        setBadge(data);
    }
    const handleOpen = () => props.setOpen(!props.open);
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>Dodaj odznakę</DialogHeader>
            <DialogBody className={'text-white'}>
                {JSON.stringify(badge)}
                <div className="space-y-5">
                    <div className={'grid grid-cols-2 gap-2'}>
                        <Select className={'text-white'}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                color={'blue'}
                                label={'Wybierz emoji'}
                                onChange={(e: any) => selectEmoji(e)}
                                selected={(element) =>
                                    element &&
                                    React.cloneElement(element, {
                                        disabled: true,
                                        className:
                                            "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                                    })
                                }>
                            {badges.map((e: any)=> <Option key={e.id} value={e.id} className={'flex items-center gap-2'}><img src={e.imageURL} className={'h-5 w-5 mr-2 object-cover'}/>{e.name}</Option>)}
                        </Select>
                        <Select className={'text-white'}
                                onChange={(e: any) => updateState('type', e)}
                                labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                color={'blue'}
                                label={'Rodzaj odznaki'}>
                            {['COMMON', 'RARE', 'UNIQUE'].map((e: any)=> <Option value={e} className={'flex items-center gap-2'}>{t('profile.badges.' + e)}</Option>)}
                        </Select>
                    </div>
                    <Input onInput={(e : any)=> updateState('pl', e.target.value, 'name')} type={'string'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Nazwa odznaki w języku polskim"} color={'blue'} crossOrigin={''} disabled={store.user && (store.user.permission & 32) != 32} className="text-white "/>
                    <Input onInput={(e : any)=> updateState('en', e.target.value, 'name')} type={'string'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Nazwa odznaki w języku angielskim"} color={'blue'} crossOrigin={''} disabled={store.user && (store.user.permission & 32) != 32} className="text-white "/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="text" color="green" onClick={()=>addMe(badge)}>
                    <span>Dodaj</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
