import { Button, Spinner } from "@material-tailwind/react";
import { observer } from "mobx-react";
import {Transaction} from "../model/Transaction";
import React from "react";

export const TransactionItemAnimation = observer(() => {
    return (
        <div className={"flex items-center justify-between w-full animate-pulse"}>
            <div className={"flex flex-col w-full"}>
                <div className={"h-3 bg-gray-800 rounded w-1/2"}></div>
                <div className={"h-2 bg-gray-800 mt-2 rounded w-1/2"}></div>
            </div>
            <div className={"text-red-500 flex items-center"}>
                <div className={"h-2 bg-red-800 rounded w-10"}></div>
                <img className={"h-7 ml-2"}
                     src={"https://cdn.discordapp.com/emojis/865516630766518292.webp?size=96&quality=lossless"}/>
            </div>

        </div>
    )
});
