import {useLocation, useNavigate, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import Home4LineIcon from 'remixicon-react/Home4LineIcon'
import Wallet from 'remixicon-react/Wallet3LineIcon'
import Warns from 'remixicon-react/ErrorWarningLineIcon'
import Mutes from 'remixicon-react/VolumeMuteLineIcon'
import Bans from 'remixicon-react/DeleteBinLineIcon'
import Kicks from 'remixicon-react/UserUnfollowLineIcon'
import Stairs from 'remixicon-react/VipCrown2LineIcon'
import banner from "../assets/banner.png";
import {If, Then, When} from "react-if";
import {observer} from "mobx-react";
import {TransactionItemAnimation} from "../components/animation/TransactionItemAnimation";
import {PunishmentItemAnimation} from "../components/animation/PunishmentItemAnimation";
import {LevelCardPreview} from "../components/LevelCardPreview";
import {TransactionItem} from "../components/profile/TransactionItem";
import {PunishmentItem} from "../components/profile/PunishmentItem";
import {Badge} from "../components/Badge";
import {wait} from "../utils/global";


export const ProfilePage3: React.FC = observer(() => {
    const location = useLocation();
    let params = useParams();
    const navigate = useNavigate();
    const store = useStore();
    const [profile, setProfile]: any = useState(null);
    const [page, setPage]: any = useState(0);
    const [levelLeaderboard, setLevelLeaderboard]: any = useState(0);
    const [economyLeaderboard, setEconomyLeaderboard]: any = useState(0);
    const [transactions, setTransactions]: any = useState(null);
    const [warns, setWarns] : any = useState(null);
    const [mutes, setMutes] : any = useState(null);
    const [bans, setBans] : any = useState(null);
    const [kicks, setKicks] : any = useState(null);
    const [discordData, setDiscordData]: any = useState(null);
    useEffect(() => {
        if(transactions == null && page == 1){
            if(store.user !=null && (store.user.permission & 128) === 128 || store.user.id === params.id!) {
                store.loadTransactions(params.id!).then((e)=> {
                    wait(1).then(()=> {
                        setTransactions(e);
                    })
                })
            }
        }
        if(warns == null && page == 2){
            if(store.user !=null && (store.user.permission & 256) === 256 || store.user.id === params.id!) {
                store.loadWarns(params.id!).then((e)=> {
                    wait(1).then(()=> {
                        setWarns(e);
                    });
                })
            }
        }
        if(mutes == null && page == 3){
            if(store.user !=null && (store.user.permission & 512) === 512 || store.user.id === params.id!) {
                store.loadMutes(params.id!).then((e)=> {
                    wait(1).then(()=> {
                        setMutes(e);
                    });
                })
            }
        }
        if(bans == null && page == 4){
            if(store.user !=null && (store.user.permission & 1024) === 1024 || store.user.id === params.id!) {
                store.loadBans(params.id!).then((e)=> {
                    wait(1).then(()=> {
                        setBans(e);
                    });
                })
            }
        }
        if(bans == null && page == 5){
            if(store.user !=null && (store.user.permission & 2048) === 2048 || store.user.id === params.id!) {
                store.loadKick(params.id!).then((e)=> {
                    wait(1).then(()=> {
                        setKicks(e);
                    });
                })
            }
        }
    }, [page]);
    useEffect(() => {
        store.setLoading(true);
        setLevelLeaderboard(0);
        setEconomyLeaderboard(0);
        store.loadProfile(params.id!).then(e=> {
            if(e!=null){
                setProfile(e);
                store.getDiscordData(params.id!).then((m: any)=> {
                    setDiscordData(m);
                }).finally(() => {
                    store.setLoading(false);
                });
            }
        }).finally(async ()=> {
            setEconomyLeaderboard(await store.loadLeaderboardRankLevel(params.id!, 'GCOINS'));
            setLevelLeaderboard(await store.loadLeaderboardRankLevel(params.id!, 'LEVEL'))
        });
    }, [location]);
    const { t, i18n } = useTranslation();
    if(store.loading){
        return <></>;
    }
    const getHighestRole = () => {
        if(discordData == null) return <></>
        let data = Object.assign({}, discordData.member);
        let roles = data.roles.filter((e: any)=> e.hoist);
        roles.sort((e: any, d: any)=> d.rawPosition - e.rawPosition);
        return (<>
            <div className={"text-sm -mt-1"}
                 style={{color: `#${roles[0].color.toString(16).padStart(6, '0')}`}}>{roles[0].name.replace('∙ ', '')}</div>
        </>)
    }
    return (
        <>
            <div className={"h-full w-full"}>
                <div className="select-none text-white w-full">
                    <div className={"flex flex-col items-center text-xl w-full text-white font-montserrat"}>
                        {profile !=null ? (
                            <div className={"h-auto w-full"}>
                                <div className={"w-full"}>
                                    <div
                                        className={'w-11/12 md:w-8/12 h-[30dvh] rounded-xl relative mt-20 mx-auto'}
                                        style={{
                                            backgroundImage: `url(https://cdn.discordapp.com/banners/${profile.id}/${profile.banner}?size=1024)`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            backgroundColor: profile.bannerColor != null ? `#${profile.bannerColor.toString(16)}` : undefined
                                        }}>
                                        <img
                                            className={"-bottom-24 absolute h-24 md:h-auto rounded-2xl left-[50%] translate-y-[-50%] translate-x-[-50%]"}
                                            src={`https://cdn.discordapp.com/avatars/${profile.id}/${profile.avatar}`}/>
                                    </div>
                                    <div className={"mx-auto text-center mt-9 font-montserrat"}>
                                        <div className={"text-3xl font-bold"}>{profile.global_name}</div>
                                        <div className={"text-xl text-gray-500 -mt-1"}>{profile.username}</div>
                                        {getHighestRole()}
                                        <div
                                            className={'flex flex-row items-center justify-center mt-3 space-x-5'}>
                                            {profile.badges.map((e: any) => (<Badge badge={e}/>))}
                                        </div>
                                    </div>
                                    <div className={"mx-20 mt-8"}>
                                    <div className={"p-2 w-full"}>
                                            <div
                                                className={"flex flex-col xl:flex-row xl:items-center justify-center xl:space-x-10"}>
                                                <div
                                                    className={`${page === 0 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                    onClick={() => setPage(0)}>
                                                    <span className={"mr-2"}><Home4LineIcon size={'32px'}/></span>{t('profile.menu.general')}
                                                </div>
                                                <LevelCardPreview id={profile.id as string}>
                                                    <div
                                                        className={`text-blue-500 font-semibold text-xl flex items-center cursor-context-menu`}>
                                                        <span className={"mr-2"}><Stairs
                                                            size={'32px'}/></span>{t('profile.levelCard')}
                                                    </div>
                                                </LevelCardPreview>
                                                <If condition={store.user && store.user.id === profile.id || store.user && (store.user.permission & 128) === 128}>
                                                    <Then>
                                                        <div
                                                            className={`${page === 1 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                            onClick={() => setPage(1)}>
                                                            <span className={"mr-2"}><Wallet size={'32px'}/></span>{t('profile.menu.transactions')}
                                                        </div>
                                                    </Then>
                                                </If>
                                                <If condition={store.user &&store.user.id === profile.id || store.user && (store.user.permission & 256) === 256}>
                                                    <Then>
                                                        <div
                                                            className={`${page === 2 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                            onClick={() => setPage(2)}>
                                                            <span className={"mr-2"}><Warns
                                                                size={'32px'}/></span>{t('profile.menu.warns')}
                                                        </div>
                                                    </Then>
                                                </If>
                                                <If condition={store.user && store.user.id === profile.id || store.user && (store.user.permission & 512) === 512}>
                                                    <Then>
                                                        <div
                                                            className={`${page === 3 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                            onClick={() => setPage(3)}>
                                                            <span className={"mr-2"}><Mutes
                                                                size={'32px'}/></span>{t('profile.menu.mutes')}
                                                        </div>
                                                    </Then>
                                                </If>
                                                <If condition={store.user && store.user.id === profile.id || store.user && (store.user.permission & 2048) === 2048}>
                                                    <Then>
                                                        <div
                                                            className={`${page === 5 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                            onClick={() => setPage(5)}>
                                                            <span className={"mr-2"}><Kicks
                                                                size={'32px'}/></span>{t('profile.menu.kicks')}
                                                        </div>
                                                    </Then>
                                                </If>
                                                <If condition={store.user && store.user.id === profile.id || (store.user && (store.user.permission & 1024) === 1024)}>
                                                    <Then>
                                                        <div
                                                            className={`${page === 4 ? 'text-white' : 'text-blue-500'} font-semibold text-xl flex items-center cursor-pointer`}
                                                            onClick={() => setPage(4)}>
                                                            <span className={"mr-2"}><Bans
                                                                size={'32px'}/></span>{t('profile.menu.bans')}
                                                        </div>
                                                    </Then>
                                                </If>
                                            </div>
                                        </div>

                                        <div className={'mr-60 ml-52'}>
                                            <If condition={page == 0}>
                                                <Then>
                                                    <div className={"grid md:grid-cols-3 gap-2 mt-5 w-full mx-4"}>
                                                        {discordData != null ? (
                                                            <div className={'rounded-lg bg-[#1111114a] p-4'}>
                                                                <div className={"flex flex-col items-start"}>
                                                                    <div className={"text-base font-lg"}>Wspiera serwer:
                                                                    </div>
                                                                    <div className={'flex items-center space-x-1'}>
                                                                        <img
                                                                            hidden={discordData!.member.premiumSinceTimestamp == null}
                                                                            className={"h-4"}
                                                                            src={"https://cdn.discordapp.com/emojis/1204873057173250058.webp?size=96"}/>
                                                                        <div
                                                                            className={"text-gray-500 text-sm"}>{discordData!.member.premiumSinceTimestamp != null ? 'tak' : 'nie'}
                                                                        </div>
                                                                        <When
                                                                            condition={discordData!.member.premiumSinceTimestamp != null}>
                                                                            <div
                                                                                className={"text-gray-500 text-sm"}>(od {new Date(discordData!.member.premiumSinceTimestamp).toLocaleString()})
                                                                            </div>
                                                                        </When>
                                                                    </div>
                                                                </div>
                                                                <div className={"flex flex-col items-start"}>
                                                                    <div className={"text-base font-medium"}>Data
                                                                        utworzenia konta:
                                                                    </div>
                                                                    <div
                                                                        className={"text-gray-500 text-sm"}>{new Date(discordData!.user.createdTimestamp).toLocaleString()}
                                                                    </div>
                                                                </div>
                                                                <div className={"flex flex-col items-start"}>
                                                                    <div className={"text-base font-lg"}>Data dołączenia
                                                                        na
                                                                        serwer:
                                                                    </div>
                                                                    <div
                                                                        className={"text-gray-500 text-sm"}>{new Date(discordData!.member.joinedTimestamp).toLocaleString()}
                                                                    </div>
                                                                </div>
                                                                <div className={"flex flex-col items-start"}>
                                                                    <div className={"text-base font-lg"}>Pseudonim na
                                                                        serwerze:
                                                                    </div>
                                                                    <div
                                                                        className={"text-gray-500 text-sm"}>{discordData!.member.displayName ?? t('global.undefined')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (<div>Loading</div>)}
                                                        <div className={'rounded-lg bg-[#1111114a] p-4'}>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={"text-base font-lg xl:mr-1"}>Odbiór
                                                                    nagrody
                                                                    dziennej:
                                                                </div>
                                                                <div
                                                                    className={"text-gray-500 text-sm"}>{new Date(profile.cooldowns.daily).toLocaleDateString()} {new Date(profile.cooldowns.daily).toLocaleTimeString()}
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={"text-base font-lg xl:mr-1"}>Odbiór
                                                                    nagrody
                                                                    tygodniowej:
                                                                </div>
                                                                <div
                                                                    className={"text-gray-500 text-sm"}>{new Date(profile.cooldowns.weekly).toLocaleDateString()} {new Date(profile.cooldowns.weekly).toLocaleTimeString()}
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={"text-base font-lg xl:mr-1"}>Odbiór nagrody
                                                                    miesięcznej:
                                                                </div>
                                                                <div
                                                                    className={"text-gray-500 text-sm"}>{new Date(profile.cooldowns.monthly).toLocaleDateString()} {new Date(profile.cooldowns.monthly).toLocaleTimeString()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'rounded-lg bg-[#1111114a] p-4'}>
                                                            {/*<div>{JSON.stringify(profile, null, 2)}</div>*/}
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={'text-base font-lg'}>{t('profile.gCoinState')}:</div>
                                                                <div className={'flex items-center text-sm'}>
                                                                    <div
                                                                        className={"xl:ml-1 text-gray-500"}>{profile.gCoins}</div>
                                                                    <img className={"ml-1 h-5"}
                                                                         src={"https://cdn.discordapp.com/emojis/865516630766518292.webp?size=96&quality=lossless"}/>
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={'text-base font-lg'}>{t('profile.reachedLevel')}:</div>
                                                                <div
                                                                    className={"xl:ml-1 text-gray-500 text-sm"}>{profile.level}
                                                                    <span
                                                                        className={"text-gray-500 text-sm ml-1"}>[{profile.xp}/{profile.xpRequired}]</span>
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={'text-base font-lg'}>{t('profile.placeEconomy')}:</div>
                                                                <div
                                                                    className={"xl:ml-1 text-gray-500 text-sm"}>#{economyLeaderboard}
                                                                </div>
                                                            </div>
                                                            <div className={"flex flex-col items-start"}>
                                                                <div className={'text-base font-lg'}>{t('profile.placeLevel')}:</div>
                                                                <div
                                                                    className={"xl:ml-1 text-gray-500 text-sm"}>#{levelLeaderboard}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page == 1}>
                                                <Then>
                                                    <div className={"grid xl:grid-cols-3 gap-4 mt-10 mx-4"}>
                                                        {transactions !=null ? (<>
                                                            <div>

                                                                {transactions.slice(0, 5).map((e: any) => (
                                                                    <TransactionItem transaction={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {transactions.slice(5, 10).map((e: any) => (
                                                                    <TransactionItem transaction={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {transactions.slice(10, 15).map((e: any) => (
                                                                    <TransactionItem transaction={e}/>))}
                                                            </div>
                                                        </>) : ([1, 2, 3].map(()=> (<>
                                                            <div className={'space-y-12'}>
                                                                {[1, 2, 3].map(()=> <TransactionItemAnimation/>)}
                                                            </div>
                                                        </>)))}

                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page == 2}>
                                                <Then>
                                                    <div className={"grid xl:grid-cols-3 gap-4 mt-10 mx-4"}>
                                                        {warns != null ? (<>
                                                            <div>

                                                                {warns.slice(0, 5).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {warns.slice(5, 10).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {warns.slice(10, 15).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                        </>) : ([1, 2, 3].map(() => (<>
                                                            <div className={'space-y-4'}>
                                                                {[1, 2, 3].map(() => <PunishmentItemAnimation/>)}
                                                            </div>
                                                        </>)))}
                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page == 3}>
                                                <Then>
                                                    <div className={"grid xl:grid-cols-3 gap-4 mt-10 mx-4"}>
                                                        {mutes != null ? (<>
                                                            <div>

                                                                {mutes.slice(0, 5).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {mutes.slice(5, 10).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {mutes.slice(10, 15).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                        </>) : ([1, 2, 3].map(() => (<>
                                                            <div className={'space-y-4'}>
                                                                {[1, 2, 3].map(() => <PunishmentItemAnimation/>)}
                                                            </div>
                                                        </>)))}
                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page == 4}>
                                                <Then>
                                                    <div className={"grid xl:grid-cols-3 gap-4 mt-10 mx-4"}>
                                                        {bans != null ? (<>
                                                            <div>

                                                                {bans.slice(0, 5).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {bans.slice(5, 10).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {bans.slice(10, 15).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                        </>) : ([1, 2, 3].map(() => (<>
                                                            <div className={'space-y-4'}>
                                                                {[1, 2, 3].map(() => <PunishmentItemAnimation/>)}
                                                            </div>
                                                        </>)))}
                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page == 5}>
                                                <Then>
                                                    <div className={"grid xl:grid-cols-3 gap-4 mt-10 mx-4"}>
                                                        {kicks != null ? (<>
                                                            <div>

                                                                {kicks.slice(0, 5).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {kicks.slice(5, 10).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className={"text-2xl xl:text-4xl font-montserrat font-bold flex items-start justify-start text-left"}>

                                                                </div>
                                                                {kicks.slice(10, 15).map((e: any) => (
                                                                    <PunishmentItem punishment={e}/>))}
                                                            </div>
                                                        </>) : ([1, 2, 3].map(() => (<>
                                                            <div className={'space-y-4'}>
                                                                {[1, 2, 3].map(() => <PunishmentItemAnimation/>)}
                                                            </div>
                                                        </>)))}
                                                    </div>
                                                </Then>
                                            </If>
                                            <If condition={page === 1 && (transactions != null && transactions.length === 0) || page === 2 && (warns != null && warns.length === 0) || page === 3 && (mutes != null && mutes.length === 0)|| page === 4 && (bans != null && bans.length === 0) || page === 5 && (kicks != null && kicks.length === 0)}>
                                                <Then>
                                                    <div className={"w-full my-24 text-center font-bold text-3xl"}>
                                                        {t('notFound.title')}
                                                    </div>
                                                </Then>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : (<>
                            <div className={"flex flex-col items-center justify-center w-full h-full"}>
                                <div
                                    className={"flex flex-col items-center justify-center text-white w-full h-full text-center font-montserrat"}>
                                    <div className="flex flex-row items-center space-x-7 mb-14">
                                        <img src={banner} className="h-8 xl:h-16" alt="Banner"/>
                                        <div className="text-2xl xl:text-4xl font-bold font-montserrat">
                                            X
                                        </div>
                                        <img
                                            src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0b52aa9e99b832574a53_full_logo_blurple_RGB.png"
                                            alt="discord"
                                            className="h-5 xl:h-8"
                                        />
                                    </div>
                                    <div className={"text-3xl font-bold mt-32"}>Ten profil nie istnieję</div>
                                    <div className={"text-lg text-gray-500"}>Dzieję się tak gdy użytkownik nie jest
                                        członkiem serwera lub go opuścił
                                    </div>
                                    <button
                                        className="flex items-center mt-24 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                                        onClick={() => {
                                            navigate({pathname: '/'})
                                        }}
                                    >
                                        Wróć na stronę główną
                                    </button>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </>
    );
});
