import {ThemeProvider, Tooltip, Typography} from "@material-tailwind/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import children = ThemeProvider.propTypes.children;
import {useStore} from "../../store/store";
import {When} from "react-if";
import {Editable, Slate, withReact} from "slate-react";
import {createEditor} from "slate";
import MarkdownPreviewExample from "./MarkdownPreview";
import PlusIcon from 'remixicon-react/AddCircleLineIcon'
import MinusIcon from 'remixicon-react/CloseCircleLineIcon'
import LinkIcon from 'remixicon-react/LinkIcon'
import PaletteIcon from 'remixicon-react/PaletteLineIcon'
import {SetLinkModal} from "../modal/SetLinkModal";
import {PickColorModal} from "../modal/PickColorModal";
import EmojiPicker from 'emoji-picker-react';
import {toBase64} from "../../utils/global";
import {AddLinkWithLabel} from "../modal/AddLinkWithLabel";

export const MessageVisualization = (props: {edit: boolean, data?: {content?: any, embed?: any, attachments: any[], reactions: any[], link: any}, updateEmbed: Function, updateContent: Function, updateAttachments: Function, updateReactions: Function, updateLink: Function}) => {
    const { t, i18n } = useTranslation();
    const store = useStore();
    const editor = withReact(createEditor())
    const [openAuthorIcon, setOpenAuthorIcon] = useState(false);
    const [openThumbnailIcon, setOpenThumbnailIcon] = useState(false);
    const [openImageIcon, setOpenImageIcon] = useState(false);
    const [openFooterIcon, setOpenFooterIcon] = useState(false);
    const [openColor, setOpenColor] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(false);
    const [openLink, setOpenLink] = useState(false);
    const value = [{ type: 'block', children: [{ text: 'test' }] }]
    const updateEmbed = (name: string, data: any, subTree?: string) => {
        props.updateEmbed(name, data, subTree);
    }
    function handleChange(e: any) {
        console.log(e.target.files);
        let files : any = [];
        Array.from(e.target.files).forEach((e: any)=> {
            files.push(e)
        })
        props.updateAttachments(files);
    }
    function removeFile(file: any) {
        let ds = Object.assign([], props.data?.attachments);
        props.updateAttachments(ds.filter(e=> e!=file));
    }
    function removeReaction(reaction: any) {
        let ds = Object.assign([], props.data?.reactions);
        props.updateReactions(ds.filter(e=> e!=reaction));
    }
    function addReaction(reaction: any) {
        let ds = Object.assign([], props.data?.reactions);
        ds.push(reaction)
        props.updateReactions(ds);
        setOpenEmoji(false)
    }
    function removeLink(link: any) {
        let ds = Object.assign([], props.data?.link);
        props.updateLink(ds.filter((e: any)=> e!=link));
    }
    function addLink(link: any) {
        let ds = Object.assign([], props.data?.link);
        ds.push(link)
        props.updateLink(ds);
    }
    const buildEmbed = () => {
        if(props.data?.embed !=null) {
             return (<>
                 <div className={'flex items-center space-x-2'}>
                     <div className={'flex flex-col space-y-2'}>
                         <Tooltip content={'Wybierz kolor embed'}>
                             <div className={'flex text-lg items-center space-x-2 cursor-pointer'}
                                  onClick={() => setOpenColor(true)}>
                                 <PaletteIcon color={'green'}/>
                             </div>
                         </Tooltip>
                         <When
                             condition={props.data?.embed.color != null}>
                             <Tooltip content={'Usuń kolor embed'}>
                                 <div className={'flex text-lg items-center space-x-2 cursor-pointer'}
                                      onClick={() => updateEmbed('color', undefined)}>
                                         <MinusIcon color={'red'}/>
                                 </div>
                             </Tooltip>
                         </When>
                     </div>
                     <div className={'bg-gray-900 max-w-[60vh] min-w-[60vh] rounded-lg p-3 border-l-4'} style={{
                         borderColor: "#" +
                             (props.data.embed.color & 0x00FFFFFF).toString(16).padStart(6, '0')
                     }}>
                         <div className={'flex justify-between w-full'}>
                             <div className={'w-full'}>
                                 <div className={'flex flex-row space-x-2 w-full'}>
                                     <div className={`flex items-center space-x-2 ${props.data.embed && props.data.embed.author ==null ? 'w-full' : ''}`}>
                                         <When condition={props.data?.embed && props.data?.embed.author == null}>
                                             <div className={'flex text-sm items-center space-x-2 w-full'}
                                                  onClick={() => updateEmbed('author', {})}>
                                                 <PlusIcon className={'cursor-pointer'} color={'green'}/>
                                                 <div>Dodaj autora</div>
                                             </div>
                                         </When>
                                         <When condition={props.data?.embed.author != null}>
                                             <div onClick={() => updateEmbed('author', undefined)}>
                                                 <MinusIcon className={'cursor-pointer'} color={'red'}/>
                                             </div>
                                         </When>
                                     </div>
                                     <div className={'flex items-center space-x-2 w-11/12'}>
                                         <When
                                             condition={props.data?.embed.author != null && props.data?.embed.author.icon_url != null}>
                                             <img className={'h-8 w-8 rounded-full'}
                                                  src={props.data?.embed.author != null ? props.data?.embed.author.icon_url : ''}
                                                  alt={'avatar'}/>
                                         </When>
                                         <When condition={props.data?.embed.author != null}>
                                             <div
                                                 className={'text-sm font-medium w-full'}>
                                                 <MarkdownPreviewExample
                                                     initialText={(props.data.embed.author && props.data.embed.author.name) !=null ? props.data.embed.author.name : ''}
                                                     onType={(e: any) => updateEmbed('name', e, 'author')}/>
                                             </div>
                                         </When>
                                     </div>
                                 </div>
                                 <div className={'flex flex-row items-center space-x-2'}>
                                     <div className={'flex items-center space-x-2'}>
                                         <When condition={props.data?.embed.title == null}>
                                             <div className={'flex text-xs xl:text-sm items-center space-x-2'}
                                                  onClick={() => updateEmbed('title', '')}>
                                                 <PlusIcon className={'cursor-pointer'} color={'green'}/>
                                                 <div>Dodaj tytuł</div>
                                             </div>
                                         </When>
                                         <When condition={props.data?.embed.title != null}>
                                             <div onClick={() => updateEmbed('title', undefined)}>
                                                 <MinusIcon className={'cursor-pointer'} color={'red'}/>
                                             </div>
                                         </When>
                                     </div>
                                     <When condition={props.data?.embed.title != null}>
                                         <div
                                             className={'text-xs xl:text-sm font-bold mt-2 whitespace-pre-wrap break-keep w-11/12'}>
                                             <MarkdownPreviewExample
                                                 initialText={props.data.embed.title ?? ''}
                                                 onType={(e: any) => updateEmbed('title', e)}/>
                                         </div>
                                     </When>
                                 </div>
                                 <div className={'flex flex-row items-center space-x-2 text-xs xl:text-sm'}>
                                     <div className={'flex items-center'}>
                                         <When condition={props.data?.embed.description == null}>
                                             <div className={'flex text-xs xl:text-sm items-center space-x-2'}
                                                  onClick={() => updateEmbed('description', '')}>
                                                 <PlusIcon className={'cursor-pointer'} color={'green'}/>
                                                 <div>Dodaj opis</div>
                                             </div>
                                         </When>
                                         <When condition={props.data?.embed.description != null}>
                                             <div onClick={() => updateEmbed('description', undefined)}>
                                                 <MinusIcon className={'cursor-pointer'} color={'red'}/>
                                             </div>
                                         </When>
                                     </div>
                                     <When condition={props.data?.embed.description != null}>
                                         <div className={'w-11/12'}>
                                             <MarkdownPreviewExample
                                                 initialText={props.data?.embed.description == null ? props.data?.embed.description :''}
                                                 onType={(e: any) => updateEmbed('description', e)}/>
                                         </div>
                                     </When>
                                 </div>
                             </div>
                             <When condition={props.data?.embed.thumbnail != null}>
                                 <img className={'h-20 w-20 rounded-xl mt-5'}
                                      src={props.data?.embed.thumbnail != null ? props.data?.embed.thumbnail.url : ''}
                                      alt={'avatar'}/>
                             </When>
                         </div>
                         <When condition={props.data?.embed.image != null}>
                             <img className={'h-[50vh] rounded-xl mt-5'}
                                  src={props.data?.embed.image != null ? props.data?.embed.image.url : ''}
                                  alt={'avatar'}/>
                         </When>
                         <div className={'flex items-center space-x-2 mt-2 w-full'}>
                             <div className={`flex items-center text-center ${props.data?.embed.footer == null ? 'w-full' : ''}`}>
                                 <When condition={props.data?.embed.footer == null}>
                                     <div className={`flex text-xs xl:text-sm text-gray-500 items-center space-x-2`}
                                          onClick={() => updateEmbed('text', '', 'footer')}>
                                         <PlusIcon className={'cursor-pointer'} color={'green'}/>
                                         <div>Dodaj footer</div>
                                     </div>
                                 </When>
                                 <When condition={props.data?.embed.footer != null}>
                                     <div onClick={() => updateEmbed('footer', undefined)}>
                                         <MinusIcon className={'cursor-pointer'} color={'red'}/>
                                     </div>
                                 </When>
                             </div>
                             <div className={'flex items-center space-x-2 w-10/12'}>
                                 <When
                                     condition={props.data?.embed.footer != null && props.data?.embed.footer.icon_url != null}>
                                     <img className={'h-5 w-5 rounded-full'}
                                          src={props.data?.embed.footer != null ? props.data?.embed.footer.icon_url : ''}
                                          alt={'avatar'}/>
                                 </When>
                                 <When condition={props.data?.embed.footer != null}>
                                     <div className={'text-xs text-gray-500 w-full'}>
                                         <MarkdownPreviewExample initialText={(props.data?.embed.footer && props.data?.embed.footer.text) !=null ? props.data?.embed.footer.text : ''}
                                                                 onType={(e: any) => updateEmbed('text', e, 'footer')}/>
                                     </div>
                                 </When>
                             </div>
                         </div>
                         <div className={'mt-5'}>
                             <div className={'font-bold text-lg xl:text-xl my-2'}>Akcję w Embed</div>
                             <When
                                 condition={props.data?.embed.author != null && props.data?.embed.author.icon_url == null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => setOpenAuthorIcon(true)}>
                                     <PlusIcon color={'green'}/>
                                     <div>Dodaj ikonę autora</div>
                                 </div>
                             </When>
                             <When
                                 condition={props.data?.embed.author != null && props.data?.embed.author.icon_url != null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => updateEmbed('icon_url', undefined, 'author')}>
                                     <MinusIcon color={'red'}/>
                                     <div>Usuń ikonę autora</div>
                                 </div>
                             </When>
                             <When condition={props.data?.embed.thumbnail == null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => setOpenThumbnailIcon(true)}>
                                     <PlusIcon color={'green'}/>
                                     <div>Dodaj mniejszą ikonę [Thumbnail]</div>
                                 </div>
                             </When>
                             <When
                                 condition={props.data?.embed.thumbnail != null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => updateEmbed('thumbnail', undefined)}>
                                     <MinusIcon color={'red'}/>
                                     <div>Usuń mniejszą ikonę [Thumbnail]</div>
                                 </div>
                             </When>
                             <When condition={props.data?.embed.image == null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => setOpenImageIcon(true)}>
                                     <PlusIcon color={'green'}/>
                                     <div>Dodaj zdjęcje</div>
                                 </div>
                             </When>
                             <When
                                 condition={props.data?.embed.image != null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => updateEmbed('image', undefined)}>
                                     <MinusIcon color={'red'}/>
                                     <div>Usuń zdjęcie</div>
                                 </div>
                             </When>
                             <When
                                 condition={props.data?.embed.footer != null && props.data?.embed.footer.icon_url == null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => setOpenFooterIcon(true)}>
                                     <PlusIcon color={'green'}/>
                                     <div>Dodaj ikonę footer</div>
                                 </div>
                             </When>
                             <When
                                 condition={props.data?.embed.footer != null && props.data?.embed.footer.icon_url != null}>
                                 <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                      onClick={() => updateEmbed('icon_url', undefined, 'footer')}>
                                     <MinusIcon color={'red'}/>
                                     <div>Usuń ikonę footer</div>
                                 </div>
                             </When>
                         </div>
                     </div>
                 </div>
             </>)
                 ;
        }
    }
    return (
        <>
            <div className={'font-montserrat w-full'}>
                <div className={'rounded-2xl w-full'}>
                    <div className={'flex space-x-2 items-start w-full'}>
                        <img className={'h-9 xl:h-12 rounded-full'}
                             src={'https://cdn.discordapp.com/avatars/1295080001955168398/bcec8ce28d03d11647a7bdae1c2fd56d'}
                             alt={'avatar'}/>
                        <div className={'flex flex-col w-full'}>
                            <div className={'flex items-center space-x-2'}>
                                <div className={'text-red-500 font-bold text-sm xl:text-xl'}>GENESIS</div>
                                <div className={'text-xs xl:text-sm text-gray-500'}>{new Date().toLocaleString()}</div>
                            </div>
                            <div className={'flex space-x-2 w-full'}>
                                <When condition={props.data?.content == null}>
                                    <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                         onClick={() => props.updateContent('')}>
                                        <PlusIcon color={'green'}/>
                                        <div>Dodaj treść</div>
                                    </div>
                                </When>
                                <When condition={props.data?.content != null}>
                                    <div onClick={() => props.updateContent(undefined)}>
                                        <MinusIcon className={'cursor-pointer'} color={'red'}/>
                                    </div>
                                </When>
                                <When condition={props.data?.content != null}>
                                    <div className={'text-xs xl:text-sm w-full'}>
                                        <MarkdownPreviewExample
                                            initialText={props.data?.content == undefined ? 'Wpisz tekst tutaj' : props.data?.content}
                                            onType={(e: any) => props.updateContent(e)}/>
                                    </div>
                                </When>
                            </div>
                            <When condition={props.data?.embed == null}>
                                <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                     onClick={() => props.updateEmbed({})}>
                                    <PlusIcon color={'green'}/>
                                    <div>Dodaj embed</div>
                                </div>
                            </When>
                            <When condition={props.data?.embed != null}>
                                <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                     onClick={() => props.updateEmbed(undefined)}>
                                    <MinusIcon color={'red'}/>
                                    <div>Usuń embed</div>
                                </div>
                            </When>
                            {buildEmbed()}
                            <div hidden={props.edit}
                                 className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                 onClick={() => document.getElementById('pick_image_for_planned_message')!.click()}>
                                <PlusIcon color={'green'}/>
                                <div>Dodaj zdjęcie</div>
                            </div>
                            <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                 onClick={() => setOpenEmoji(!openEmoji)}>
                                <PlusIcon color={'green'}/>
                                <div>Dodaj reakcję</div>
                            </div>
                            <When condition={props.data?.link.length < 5}>
                                <div className={'flex text-xs xl:text-sm items-center space-x-2 cursor-pointer'}
                                     onClick={() => setOpenLink(!openLink)}>
                                    <PlusIcon color={'green'}/>
                                    <div>Dodaj link</div>
                                </div>
                            </When>
                            <input id={'pick_image_for_planned_message'} multiple={true} hidden={true} type="file"
                                   accept="image/*" onChange={handleChange}/>
                            <div className={'grid grid-cols-2'}>
                                {props.data?.attachments.map((e: any) => (<>
                                    <div className={'relative'}>
                                        <img
                                            src={props.edit ? `data:${e.type};base64,${toBase64(e.data.data)}` : URL.createObjectURL(e)}/>
                                        <div hidden={props.edit} className={'absolute top-2 right-2 cursor-pointer'}
                                             onClick={() => removeFile(e)}>
                                            <MinusIcon color={'red'}/>
                                        </div>
                                    </div>
                                </>))}
                            </div>
                            <div className={'grid grid-cols-5'}>
                                {props.data?.link.map((e: any) => (<>
                                    <div
                                        className={'bg-gray-900 border border-gray-700 p-1 rounded-2xl flex items-center justify-center text-xl space-x-2 px-2 cursor-pointer'}
                                        onClick={() => removeLink(e)}>
                                        <span className={'text-xs'}>{e.label}</span>
                                        <LinkIcon size={'12'}/>
                                    </div>
                                </>))}
                            </div>
                            <div className={'grid grid-cols-5'}>
                                {props.data?.reactions.map((e: any) => (<>
                                    <div
                                        className={'bg-gray-900 border border-gray-700 p-1 rounded-2xl flex items-center justify-center text-xl space-x-2 px-2 cursor-pointer'}
                                        onClick={() => removeReaction(e)}>
                                        <span>{e}</span>
                                        <span className={'font-bold'}>1</span>
                                    </div>
                                </>))}
                            </div>
                            <EmojiPicker open={openEmoji} onEmojiClick={(e) => addReaction(e.emoji)}/>
                        </div>
                    </div>
                </div>
            </div>
            <SetLinkModal open={openAuthorIcon} setOpen={setOpenAuthorIcon} title={'Ustaw ikonę autora'}
                          description={'Ustawisz ikonę autora w embedzie'}
                          setData={(e: string) => updateEmbed('icon_url', e, 'author')}/>
            <SetLinkModal open={openThumbnailIcon} setOpen={setOpenThumbnailIcon} title={'Ustaw mniejszą ikonę'}
                          description={'Pojawi się ona po prawiej stronię od treści embedu'}
                          setData={(e: string) => updateEmbed('url', e, 'thumbnail')}/>
            <SetLinkModal open={openImageIcon} setOpen={setOpenImageIcon} title={'Ustaw zdjęcie w embed'}
                          description={'Twoje zdjęcie pojawi się w dolnej części wiadomości embed'}
                          setData={(e: string) => updateEmbed('url', e, 'image')}/>
            <SetLinkModal open={openFooterIcon} setOpen={setOpenFooterIcon} title={'Ustaw zdjęcie w embed'}
                          description={'Twoje zdjęcie pojawi się w stópce embed jako mała ikona'}
                          setData={(e: string) => updateEmbed('icon_url', e, 'footer')}/>
            <AddLinkWithLabel open={openLink} setOpen={setOpenLink} title={'Dodaj link do otwarcia'}
                          description={'Pojawi się przycisk, który umożliwi użytkowniką przejście na daną stronę po kliknięciu'}
                          setData={(e: any) => addLink(e)}/>
            <PickColorModal open={openColor} setOpen={setOpenColor} title={'Wybierz kolor'}
                            description={'Wybierz kolor embed'}
                            setData={(e: any) => updateEmbed('color', Number(`0x${e.replace('#', '')}`))}/>
        </>
    );
};
